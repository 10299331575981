/* eslint-disable react-hooks/exhaustive-deps */
import './FixReportedFramesDetails.scss';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import {Drawer} from '@mui/material';
import {IRootState} from 'shared/reducers';
import {promotionsForFrameResponseModel} from 'shared/models/frame.model';
import {validity} from 'shared/models/validity.model';
import {useFormatMessage} from 'utils/translate';
import {useTabId} from 'utils/useTabId';
import {responseValidation} from 'utils/responseValidation';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {API_GET_PROMOTIONS_FOR_FRAME} from 'config/api/constants';
import {PaperX} from 'components/PaperX';
import FrameDetails from './FrameDetails';
import EditFrameValidity from 'modules/Advertisement/AdvertisementBrowser/EditFrameValidity';

interface FixReportedFramesDetailsProps {
    frameId: string,
    internalFrameId: string,    // needed only for UI to communication between drawer and main view
    isOpen: boolean,
    onChange: (internalFrameId: string, validFrom: string, validTo: string) => void,
    validFrom: string,
    validTo: string
}

const FixReportedFramesDetails = (props: FixReportedFramesDetailsProps) => {
    const {isOpen, frameId, internalFrameId, onChange, validFrom, validTo} = props;
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();
    const tabId = useTabId();

    const lang: string = useSelector((state: IRootState) => state.userProfile.langData);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<promotionsForFrameResponseModel>(null);

    const cancelToken = useRef(null);

    const cancelQuery = () => cancelToken.current && cancelToken.current();

    useEffect(() => cancelQuery(), []);

    const getFrameDetails = (frameId: string) => {
        cancelQuery();
        setIsLoading(true);
        setData(null);
        axios.get<promotionsForFrameResponseModel>(API_GET_PROMOTIONS_FOR_FRAME(frameId), {
                params: {lang, acquireLock: true, tabId: tabId},
                cancelToken: new axios.CancelToken(
                    cancel => (cancelToken.current = cancel)
                )
            })
            .then((response) => {
                if (responseValidation(response.data)) {
                    setData(response.data);
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`,{variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`,{variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (frameId && isOpen) {
            getFrameDetails(frameId);
        } else {
            setData(null);
            cancelQuery();
        }
    }, [frameId, isOpen]);

    const handleValidityChange = (validity: validity) => onChange(internalFrameId, validity.validFrom, validity.validTo);

    return (
        <Drawer className="fixReportedFramesDetailsDrawerRoot"
                anchor="right"
                variant="persistent"
                open={isOpen}
                hideBackdrop
        >
            <LoadingOverlay show={isLoading}/>
            <div className="_directionRow">
                <PaperX className="_scrollY _fullWidth">
                    <EditFrameValidity disableEdit={!(internalFrameId)}
                                       disableDelete={!(validFrom && validTo)}
                                       frameValidity={{validFrom: validFrom, validTo: validTo}}
                                       onSave={(frameValidity) => handleValidityChange(frameValidity)}
                                       onDelete={() => onChange(internalFrameId, null, null)}
                                       showCopyTools={true}
                    />
                    {data?.promotions && data?.promotions.length
                        ?
                        data?.promotions?.map((promotion, index) => <FrameDetails key={promotion.id}
                                                                                isLast={data.promotions.length -1 === index}
                                                                                data={promotion}
                                                                                validFrom={validFrom}
                                                                                validTo={validTo}/>)
                        :
                        <>
                            <h3><FormattedMessage id="b.product"/></h3>
                            <span>-</span>
                        </>
                    }                                                       
                </PaperX>
            </div>
        </Drawer>
    );
};

export default FixReportedFramesDetails;