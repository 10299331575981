/* eslint-disable react-hooks/exhaustive-deps */
import './OptionsDialog.scss';

import {useEffect} from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'shared/reducers';
import {setPreferredCountry} from 'shared/reducers/userProfileActions';
import {
    countryMarketItem,
    DEFAULT_DATA_LANG,
    LOCAL_STORAGE_KEY_COUNTRY_MARKET
} from 'shared/reducers/userProfile';
import {
    setUserLanguageData,
    setLanguageUserInterface,
    closeOptionsDialog,
    openOptionsDialog
} from 'shared/reducers/userProfileActions';
import {getObjectFromLocalStorage} from 'utils/storageUtils';
import { Divider, InputLabel, Select } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ButtonClose from 'components/Buttons/ButtonClose';
import {CountrySelector, MarketSelector} from 'components/Selectors';
import { useFormatMessage } from 'utils/translate';

type langListObject = {
    label: string,
    value: string
}

export const dataLangList: string[] = ['bg', 'cs', 'de', 'en', 'fr', 'hr', 'hu', 'pl', 'ro', 'sk', 'sl'];

const OptionsDialog = () => {
  const translate = useFormatMessage();
  const dispatch = useDispatch();

  const countryMarketLocalStorage: countryMarketItem = getObjectFromLocalStorage(LOCAL_STORAGE_KEY_COUNTRY_MARKET);

  const optionsDialogOpen = useSelector((state: IRootState) => state.userProfile.optionsDialogOpen);
  const selectedUserInterfaceLang = useSelector((state: IRootState) => state.userProfile.langInterface);
  const selectedDataLang = useSelector((state: IRootState) => state.userProfile.langData);
  const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    // some data languages were removed; need to filter potential depreciated values from user profile
    useEffect(() => {
        if (dataLangList.indexOf(selectedDataLang) === -1) {
            onChangeLangData(DEFAULT_DATA_LANG);
        }
    }, [selectedDataLang]);

    const userInterfaceLangList: string[] = ['de', 'en', 'pl'];

  useEffect(() => {
      if (!countryMarket.market || !countryMarketLocalStorage) {
          dispatch(openOptionsDialog());
      }
  }, [countryMarket, countryMarketLocalStorage]);
  
  const langList = (lang: string[]): langListObject[] => lang.map(item => ({
      label: translate({id: item}),
      value: item
  }));

  const onChangeLangUserInterface = (value: string) => dispatch(setLanguageUserInterface(value));

  const onChangeLangData = (value: string) => dispatch(setUserLanguageData(value));

  const handleClose = () => {
      if (countryMarket.market) dispatch(closeOptionsDialog());
  };

  const handleCountryChange = (value: string) => {
    dispatch(setPreferredCountry(value));
  };

  return (
      <Dialog open={optionsDialogOpen} onClose={handleClose} className="optionsDialogRoot">
        <DialogTitle>
          <FormattedMessage id="a.settings"/>
        </DialogTitle>
        <DialogContent>
            <div className="_formRowDouble">
                <FormControl className="singleSelector">
                    <InputLabel>{translate({id: 'b.userInterfaceLanguage'})}</InputLabel>
                    <Select color="primary"
                            value={selectedUserInterfaceLang}
                            onChange={(e) => onChangeLangUserInterface(e.target.value)}
                            label={translate({id: 'b.userInterfaceLanguage'})}
                    >
                        {langList(userInterfaceLangList).map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl className="singleSelector">
                    <InputLabel>{translate({id: 'b.dataLanguage'})}</InputLabel>
                    <Select color="primary"
                            value={selectedDataLang}
                            onChange={(e) => onChangeLangData(e.target.value)}
                            label={translate({id: 'b.dataLanguage'})}
                    >
                        {langList(dataLangList).map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div className="_formRowDouble">
                <div className="singleSelector">
                    <MarketSelector required/>
                </div>
                <div className="singleSelector">
                    <CountrySelector onCountryChange={handleCountryChange} value={countryMarket.preferredCountry} required/>
                </div>
            </div>

        </DialogContent>
        <Divider/>
        <DialogActions>
          <ButtonClose onClick={handleClose} disabled={!countryMarket.market || !countryMarket.preferredCountry}/>
        </DialogActions>
      </Dialog>
  );
};
export default OptionsDialog;