/* eslint-disable react-hooks/exhaustive-deps */
import './DetailsDrawer.scss';
import {FormattedMessage} from 'react-intl';
import {Drawer} from '@mui/material';
import {PaperX} from 'components/PaperX';
import EditFrameValidity from 'modules/Advertisement/AdvertisementBrowser/EditFrameValidity';
import {validity} from 'shared/models/validity.model';

interface DetailsDrawerProps {
    internalFrameId: string,    // needed only for UI to communication between drawer and main view
    isOpen: boolean,
    onChange: (internalFrameId: string, validFrom: string, validTo: string) => void,
    validFrom: string,
    validTo: string
}

const DetailsDrawer = ({internalFrameId, isOpen, onChange, validFrom, validTo}: DetailsDrawerProps) => {

    const handleValidityChange = (validity: validity) => onChange(internalFrameId, validity.validFrom, validity.validTo);

    return (
        <Drawer className="detailsDrawerRoot"
                anchor="right"
                variant="persistent"
                open={isOpen}
                hideBackdrop
        >
            <div className="_directionRow">
                <PaperX className="_scrollY _fullWidth">
                    <div className="_header">
                        <FormattedMessage id="a.details"/>
                    </div>
                    <EditFrameValidity disableEdit={!(internalFrameId)}
                                       disableDelete={!(validFrom && validTo)}
                                       frameValidity={{validFrom: validFrom, validTo: validTo}}
                                       onSave={(frameValidity) => handleValidityChange(frameValidity)}
                                       onDelete={() => onChange(internalFrameId, null, null)}
                                       showCopyTools={true}
                    />
                </PaperX>
            </div>
        </Drawer>
    );
};

export default DetailsDrawer;