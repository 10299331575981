import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {printMediaOverviewTableRow} from 'shared/models/leaflet.model';

const dateFns = new AdapterDateFns();

export function transformBEresponse (data:printMediaOverviewTableRow[], intl?):printMediaOverviewTableRow[] {
    const printMediaOverviewData:printMediaOverviewTableRow[] = [];
    if (data) {
        if (Array.isArray(data)) {
            data.forEach((item) => {
                const {fileName, availableActions, id, isGross, banner, createdBy, modifiedBy, status, leafletTypes, mediumType, numberOfPages, validFrom, validTo, createdAt, repBranch, quickAction, language} = item;
                printMediaOverviewData.push(
                    {
                        fileName,
                        availableActions,
                        id,
                        isGross,
                        banner: banner || '',
                        status,
                        leafletTypes,
                        mediumType,
                        quickAction,
                        numberOfPages,
                        createdAt: createdAt ? dateFns.formatByString(new Date(createdAt), 'yyyy/MM/dd kk:mm:ss') : '',
                        validFrom: validFrom || '',
                        validTo: validTo || '',
                        createdBy: createdBy || '',
                        modifiedBy: modifiedBy || '',
                        repBranch: repBranch || '',
                        language
                    }
                );
            })
        }
        return printMediaOverviewData;
    }
    return printMediaOverviewData;
}