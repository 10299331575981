/* eslint-disable react-hooks/exhaustive-deps */
import './ProductDescription.scss'

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {v4 as uuid} from 'uuid';
import {FormattedMessage} from 'react-intl';
import {Button, IconButton} from '@mui/material';
import {DeleteOutline} from '@mui/icons-material';
import {API_PRODUCT_DATA_GET_DESCRIPTION, API_STATUSES} from 'config/api/constants';
import {
    productDescriptionPayload,
    productDescriptionResponseModel,
    productDescriptionUI
} from 'shared/models/productDescription.model';
import {simpleDescriptionUi} from 'shared/models/description.model';
import {categorySelectorOption, categoryTableItem, categoryType} from 'shared/models/category.model';
import {IRootState} from 'shared/reducers';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {PaperX} from 'components/PaperX';
import {MarketDisplay} from 'components/Displays';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {CategorySearch} from 'modules/MasterData/ProductCategories/CategorySearch';
import DescriptionInputX from './DescriptionInputX';
import {transformDescriptionForUI, transformForBE} from './productDescriptionIO';
import {CategoryTurboSearch} from "../Category";
import ProductDescriptionDeactivation from './ProductDescriptionDeactivation';
import { getURLParam } from 'utils/routing';

export function buildProductDescriptionTemplate(marketId: string): productDescriptionUI {
    return {
        active: true,
        alternativeDescriptions: [],
        category: {
            id: '',
            name: '',
            type: categoryType.BRICK
        },
        mainDescriptions: [
            {
                description: '',
                language: '',
                UIId: uuid()
            }
        ],
        marketId
    };
}

function validateDescription(descr: productDescriptionUI): boolean {
    if (!descr.category.id) {
        return false;
    }
    const invalidMain = descr.mainDescriptions.find((item) => !item.language || !item.description);
    const invalidAlt = descr.alternativeDescriptions.find((item) => !item.language || !item.description);
    if (invalidMain || invalidAlt) return false;
    const usedLangs = descr.mainDescriptions.map((item) => item.language);
    const uniqLangs = new Set(usedLangs);
    return usedLangs.length === uniqLangs.size;
}

interface productDescriptionProps {
    clone?: boolean;
    copyMode?: boolean,
    descriptionId?: string,
    isCreatingNewDescription?: boolean,
    isDescribingFrame?: boolean,
    onProductDescriptionChange: (productDescription: productDescriptionPayload, isDescriptionValid: boolean) => void,
}

const ProductDescription = (props: productDescriptionProps) => {
    const {clone, copyMode, descriptionId, isCreatingNewDescription, isDescribingFrame, onProductDescriptionChange} = props;

    const isCreatingNew: boolean = (getURLParam('new') === 'true') || clone || isCreatingNewDescription;

    const productDescriptionAPIGet = useApi('get', null, {errMsg: 'productDescription.err'});

    const userProfile = useSelector((state: IRootState) => state.userProfile);
    const [description, setDescription] = useState<productDescriptionUI>(buildProductDescriptionTemplate(userProfile.countryMarket.market));

    useEffect(() => {
        onProductDescriptionChange(transformForBE(description), validateDescription(description));
    }, [description]);

    useEffect(() => {
        if (descriptionId) {
            productDescriptionAPIGet.call(API_PRODUCT_DATA_GET_DESCRIPTION(descriptionId, userProfile.langData));
        }
    },[descriptionId]);

    useEffect(() => {
        if (productDescriptionAPIGet.status === API_STATUSES.IDLE){
            const resp: productDescriptionResponseModel = productDescriptionAPIGet.data;
            setDescription(transformDescriptionForUI(resp));
        }
    },[productDescriptionAPIGet.status]);

    const handleBrickSelect = (value: categoryTableItem) => {
        const {bId, bName} = value;
        if (bId && bName) {
            setDescription({
                ...description,
                category: {
                    id: bId,
                    name: bName,
                    type: categoryType.BRICK
                }});
        }
    };

    const handleQuickBrickSelect = (category: categorySelectorOption) => setDescription({
            ...description,
            category
        });

    const handleDescriptionChange = (index: number, newDescription: simpleDescriptionUi, altOrMain: string) => {
        const newDescr = {...description};
        newDescr[altOrMain][index] = newDescription;
        setDescription(newDescr)
    };

    const handleDelete = (index: number, altOrMain: string) => {
        const newDescription = {...description};
        newDescription[altOrMain].splice(index, 1);
        setDescription(newDescription)
    };

    const checkIfLanguageIsAlreadyInUse = (lang: string): boolean => {
        if (lang) {
            return description.mainDescriptions
                .map((item) => item.language)
                .filter((l) => l === lang)
                .length > 1;
        }
        return false;
    };

    const buildMainDescriptions = (): JSX.Element[] => description.mainDescriptions.map((item, idx) =>
        <div className="_formRowDouble descriptionContainer" key={item.UIId}>
            <DescriptionInputX error={checkIfLanguageIsAlreadyInUse(item.language)}
                               index={idx}
                               onChange={(key,value) => handleDescriptionChange(key, value, 'mainDescriptions')}
                               productDescription={item}
            />
            {
                description.mainDescriptions.length > 1 && <div className="deleteBtn">
                    <IconButton onClick={() => handleDelete(idx, 'mainDescriptions')}>
                        <DeleteOutline color="secondary" fontSize='small'/>
                    </IconButton>
                </div>
            }
        </div>
    );

    const buildAltDescriptions = (): JSX.Element[] => description.alternativeDescriptions.map((item, idx) =>
        <div className="_formRowDouble descriptionContainer" key={item.UIId}>
            <DescriptionInputX error={false}
                               index={idx}
                               onChange={(key,value) => handleDescriptionChange(key, value, 'alternativeDescriptions')}
                               productDescription={item}
            />
            <div className="deleteBtn">
                <IconButton onClick={() => handleDelete(idx, 'alternativeDescriptions')}>
                    <DeleteOutline color="secondary" fontSize='small'/>
                </IconButton>
            </div>
        </div>
    );

    const addDescription = (altOrMain: string) => {
        const newDescription = {...description};
        newDescription[altOrMain].push({language: '', description: '', UIId: uuid()});
        setDescription(newDescription);
    };

    const toggleActiveStatus = () => setDescription({
        ...description,
        active: !description.active
    });

    return (
        <>
            <LoadingOverlay show={productDescriptionAPIGet.status === API_STATUSES.PENDING}/>
            <PaperX className="_fullHeight _fullWidth _scrollY">
                <div className="_header">
                    <FormattedMessage id="b.productDescription"/>
                </div>
                <div className="_formRow">
                    <MarketDisplay marketId={description.marketId}/>
                </div>
                <div className="_formRowDouble">
                    {descriptionId
                        ?
                        copyMode ?
                            <CategorySearch onCategorySelect={handleQuickBrickSelect} value={description.category} categoryType={categoryType.BRICK} required/>
                            :
                            <CategorySearch onCategorySelect={() => null} value={description.category} categoryType={categoryType.BRICK} required disabled/>
                        :
                        <CategoryTurboSearch onCategorySelect={handleBrickSelect}
                                             onFrameDescriptionView={isDescribingFrame}
                                             remoteSearch
                                             customLauncher={
                                                 <CategorySearch value={description.category}
                                                                 onCategorySelect={() => null}
                                                                 categoryType={categoryType.BRICK}
                                                                 required
                                                                 disabled={!!descriptionId}
                                                 ></CategorySearch>

                                             }/>
                    }
                </div>
                <div>
                    <div>
                        <FormattedMessage id="b.mainDescription"/>
                    </div>
                    {buildMainDescriptions()}
                    <div className="addBtn">
                        <Button variant="outlined" color="primary" onClick={() => addDescription('mainDescriptions')}>
                            <FormattedMessage id='b.addMainDescription'/>
                        </Button>
                    </div>
                </div>
                <div>
                    <div>
                        <FormattedMessage id='b.alternativeDescription'/>
                    </div>
                    {buildAltDescriptions()}
                    <div className="addBtn">
                        <Button variant="outlined" color="primary" onClick={() => addDescription('alternativeDescriptions')}>
                            <FormattedMessage id='b.addAlternativeDescription'/>
                        </Button>
                    </div>
                </div>
                <ProductDescriptionDeactivation active={description.active}
                                                alternativeDescriptions={description.alternativeDescriptions}
                                                handleActiveChange={toggleActiveStatus}
                                                id={description.id}
                                                isCreatingNew={isCreatingNew}
                                                mainDescriptions={description.mainDescriptions}
                                                used={description.used}
                />
            </PaperX>
        </>
    )
};

export default ProductDescription;