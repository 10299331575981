import { lexicographicalComparator } from './sortLexicographically';

const rangeRegex = /^(\S*\d - \d\S*)$/
const capacityChunksRegex = /(\S*\d - \d\S*)|([^\s]\S*)/g

export const isRange = (s: string) => rangeRegex.test(s);
export const isNumeric = (s:string) => {
  if (typeof s !== "string") return false;
  return !isNaN(s as unknown as number) && !isNaN(parseFloat(s));
}

const rangeFrom = (s: string) => parseFloat(s.split(' - ')[0]);
const rangeTo = (s: string) => parseFloat(s.split(' - ')[1]);

export const combineMultiWordUnit = (chunks: string[]): string[] => {
  let result = chunks;
  if (chunks.length > 2 && isNumeric(chunks[0][0])) { // check for the first character being a number to exclude a result which is just words
    const [firstChunk, ...rest] = chunks;
    result = [firstChunk, rest.join(' ')] // combine multi-word unit into one word
  }
  return result;
}

export const capacitiesComparator = (a: string, b: string, locale: string): number => {
  // compareFn(a, b) return value	sort order
  // > 0	sort a after b, e.g. [b, a]
  // < 0	sort a before b, e.g. [a, b]
  // === 0	keep original order of a and b
  if (!a && b) return 1;
  if (a && !b) return -1;
  if ((!a && !b) || (typeof a !== 'string' || typeof b !== 'string')) return 0;

  const aChunks = combineMultiWordUnit(a.match(capacityChunksRegex));
  const bChunks = combineMultiWordUnit(b.match(capacityChunksRegex));

  let result = 0;

  for (let i = 0; i < aChunks.length; i++) {
    const aChunk = aChunks[i];
    const bChunk = bChunks[i];

    if (aChunks.length === 2 && aChunks.length === bChunks.length) {

      if (isRange(aChunk) && isRange(bChunk)) {
        const fromA = rangeFrom(aChunk);
        const toA = rangeTo(aChunk);
        const fromB = rangeFrom(bChunk);
        const toB = rangeTo(bChunk);
        if (fromA === fromB) {
          if (toA === toB) {
            // proceed to next chunk
            continue
          } else {
            result = toA - toB;
            break
          }
        } else {
          result = fromA - fromB;
          break
        }
      }

      if (isRange(aChunk) && isNumeric(bChunk)) {
        const fromA = rangeFrom(aChunk);
        const floatB = parseFloat(bChunk);
        if (fromA === floatB) {
          // proceed to next chunk
          continue
        } else {
          result = fromA - floatB;
          break
        }
      }

      if (isNumeric(aChunk) && isRange(bChunk)) {
        const fromB = rangeFrom(bChunk);
        const floatA = parseFloat(aChunk);
        if (floatA === fromB) {
          // proceed to next chunk
          continue
        } else {
          result = floatA - fromB;
          break
        }
      }

      if (isNumeric(aChunk) && isNumeric(bChunk)) {
        const floatA = parseFloat(aChunk);
        const floatB = parseFloat(bChunk);
        if (floatA === floatB) {
          // proceed to next chunk
          continue
        } else {
          result = floatA - floatB
          break
        }
      }

      result = lexicographicalComparator(locale)(aChunk, bChunk); // for comparing units
    } else {
      // regex returned unexpected results, let's sort lexicographically
      console.warn('unexpected parsing of capacity attribute: ', aChunks, bChunks)
      result = lexicographicalComparator(locale)(aChunk, bChunk);
    }
  }
  return result;
}