/* eslint-disable react-hooks/exhaustive-deps */
import './ProductDescriptionDialog.scss';

import {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import {API_PRODUCT_DATA_DESCRIPTION, API_STATUSES} from 'config/api/constants';
import {productDescriptionPayload, productDescriptionPOSTResponseModel} from 'shared/models/productDescription.model';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ProductDescription from './ProductDescription';
import {ButtonClose} from 'components/Buttons';
import { DialogAuxiliaryActions } from 'components/DialogAuxiliaryActions';
import { NavigationMenuButton } from 'modules/NavigationMenu';

interface productDescriptionDialogProps {
    clone: boolean;
    onClose: Function,
    onCreate: (createdProductDescriptionId: string) => void,
    open: boolean,
    isCreatingNewDescription?: boolean,
    isDescribingFrame: boolean
}

const ProductDescriptionDialog = (props:productDescriptionDialogProps) => {
    const {clone, onClose, onCreate, open, isCreatingNewDescription, isDescribingFrame} = props;
    const translate = useFormatMessage();

    const descriptionAPIPost = useApi('post', null, {errMsg: 'b.descriptionSaveErr', succMsg: 'b.descriptionSaveSucc', failureMsg: 'productDescription.duplicate'});

    const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false);
    const [descriptionPayload, setDescriptionPayload] = useState<productDescriptionPayload>(null);
    const [originalDescription, setOriginalDescription] = useState<string>(null);

    useEffect(() => {
        if (descriptionAPIPost.status === API_STATUSES.IDLE && descriptionAPIPost.data?.productDescriptionId) {
            const response: productDescriptionPOSTResponseModel = descriptionAPIPost.data;
            onCreate(response.productDescriptionId);
        }
    }, [descriptionAPIPost.status]);

    const handleSaveClick = () => {
        descriptionAPIPost.call(API_PRODUCT_DATA_DESCRIPTION, descriptionPayload);
    };

    const handleProductDescriptionChange = (description: productDescriptionPayload, descriptionValid: boolean) => {
        setIsDescriptionValid(descriptionValid);
        setDescriptionPayload(descriptionValid ? description : null);
        if (originalDescription === null && descriptionValid) {
            setOriginalDescription(JSON.stringify(description));
        }
    }

    return (
        <Dialog className={isDescribingFrame ? 'productDescriptionRoot _dialogOnDescribeFrameView ' : 'productDescriptionRoot'} open={open} fullScreen>
            <DialogContent >
                <div className="_fullHeight">
                    <LoadingOverlay show={descriptionAPIPost.status === API_STATUSES.PENDING}/>
                    <ProductDescription clone={clone}
                                        onProductDescriptionChange={handleProductDescriptionChange}
                                        isDescribingFrame={isDescribingFrame}
                                        isCreatingNewDescription={isCreatingNewDescription}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {!isDescribingFrame && (
                    <DialogAuxiliaryActions>
                        <NavigationMenuButton/>
                    </DialogAuxiliaryActions>
                )}
                <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!isDescriptionValid}>{translate({id: 'a.save'})}</Button>
                <ButtonClose onClick={() => onClose()}/>
            </DialogActions>
        </Dialog>
    );
}

export default ProductDescriptionDialog;