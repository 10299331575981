/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {promotionSuggestionsForFrame, promotionUI} from 'shared/models/promotion.model';
import {productDetailsResponseModel, productSearchResultUI} from 'shared/models/product.model';
import {BPCOResponseModel} from 'shared/models/BPCO.model';
import {handyAttributes} from 'shared/handyAttributes';
import {getCurrencyForCountry} from 'shared/reducers/userProfile';
import {useFormatMessage} from 'utils/translate';
import {PaperX} from 'components/PaperX';
import Promotion from 'components/Promotion';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ProductSearch from 'modules/Advertisement/FrameDescription/ProductSearch/ProductSearch';
import ProductDrawer, {productDrawerProps} from 'modules/Advertisement/FrameDescription/ProductDrawer/ProductDrawer';
import {extendOrOverwritePromotionType, newBPCODrawerProps, newProductDrawerProps} from 'modules/Advertisement/FrameDescription/FrameDescriptionMVP';
import {addDerivedLeafletValues, BPCODetails2productSearchResultUI, productDetails2productSearchResultUI, transformPromotionsSuggestions} from 'modules/Advertisement/FrameDescription/frameDescriptionUtils';
import {makePromotionOfProductSearch} from 'modules/Advertisement/AdvertisementsOverview/advertisementsOverviewIO';
import {transformMediaPromotionsResponse} from 'modules/Advertisement/AdvertisementSpotOverview/advertisementsMediaOverviewIO';
import BPCODrawer, {BPCODrawerProps} from 'modules/Advertisement/FrameDescription/BPCODrawer/BPCODrawer';
import {spotResponseModel} from 'shared/models/media.model';
import {useGetPromotionsForMedia} from 'modules/Advertisement/AdvertisementSpotOverview/advertisementsMediaOverviewAPI';
import PromotionsMultiSuggestions from 'modules/Advertisement/MediaDescription/PromotionsSuggestions/PromotionsMultiSuggestions';

interface advertisementBrowserDetailsProps {
    mediumId: string,
    mediumData: spotResponseModel,
    selectedLanguage: string,
    newProductDrawerOpen: boolean,
    newBPCODrawerOpen: boolean,
    isPromotionsMultiSuggestionsOpen: boolean,
    onProductDrawerClose: Function,
    onBPCODrawerClose: Function,
    onPromotionsModified: (promotions: promotionUI[]) => void,
    on409?: Function
}

const AdvertisementBrowserEditMediaPromotions = (props: advertisementBrowserDetailsProps) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();

    const {mediumData, onPromotionsModified, newBPCODrawerOpen, newProductDrawerOpen, isPromotionsMultiSuggestionsOpen, onBPCODrawerClose, onProductDrawerClose, mediumId, selectedLanguage, on409} = props;

    const [promotions, setPromotions] = useState<promotionUI[]>([]);

    const [productDrawerProperties, setProductDrawerProperties] = useState<productDrawerProps>(newProductDrawerProps);
    const [BPCODrawerProperties, setBPCODrawerProperties] = useState<BPCODrawerProps>(newBPCODrawerProps);

    const {isLoading, data, refetch} = useGetPromotionsForMedia(mediumId, selectedLanguage, true, on409);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (newProductDrawerOpen) {
            openNewProductDrawer();
        } else {
            closeProductDrawer();
        }
    }, [newProductDrawerOpen]);

    useEffect(() => {
        if (newBPCODrawerOpen) {
            openNewBPCODrawer();
        } else {
            closeBPCODrawer();
        }
    }, [newBPCODrawerOpen]);

    useEffect(() => {
        if (data && mediumData.headerData) {
            setPromotions(transformMediaPromotionsResponse(data).promotions.map((item) => (
                {   ...item,
                    derivedThemeSeasons: {
                        id: handyAttributes.themeSeason,
                        literals: mediumData.headerData.themeSeasons ? mediumData.headerData.themeSeasons.map((item) => ({literalId: item})) : []
                    },
                    derivedThemedWeeks: {
                        id: handyAttributes.countryThemedWeeks,
                        literals: mediumData.headerData.countryThemedWeeks ? mediumData.headerData.countryThemedWeeks.map((item) => ({literalId: item})) : []
                    },
                    derivedPromotionTypes: {
                        id: handyAttributes.promotionType,
                        literals: []
                    }
                }
            )))
        }
    }, [data, mediumData]);

    const updateStateAndNotifyParent = (listOfPromotions: promotionUI[]) => {
        setPromotions(listOfPromotions);
        onPromotionsModified(listOfPromotions);
    }

    const handleProductSelect = (selectedProduct: productSearchResultUI) => {
        const newPromotions = [...promotions];
        newPromotions.push(makePromotionOfProductSearch(selectedProduct, mediumData?.headerData?.themeSeasons, mediumData?.headerData?.countryThemedWeeks, null, getCurrencyForCountry(mediumData?.country)));
        updateStateAndNotifyParent(newPromotions);
        enqueueSnackbar(`${translate({id: 'b.productAdded'})}`, {variant: 'success', persist: false});
    };

    const handlePromotionUpdate = (key, value, id) => {
        const newPromotions = [...promotions];
        const targetIndex = newPromotions.findIndex((promotion) => promotion.UIId === id);
        newPromotions[targetIndex][key] = value;
        updateStateAndNotifyParent(newPromotions);
    };

    const handlePromotionError = (error: boolean, promotionId: string) => handlePromotionUpdate('hasError', error, promotionId);

    const handlePromotionRemoval = (promotionId:string) =>{
        const newPromotions = promotions.filter((promotion) => promotion.UIId !== promotionId);
        updateStateAndNotifyParent(newPromotions);
    };

    const handlePromotionClone = (id: string) => {
        const uniqueKey: string = uuidv4();
        const filterResult: promotionUI = promotions.find((item) => item.UIId === id);
        const clonedPromotions: promotionUI = _.cloneDeep(filterResult);
        if (clonedPromotions) {
            clonedPromotions.UIId = uniqueKey;
            delete clonedPromotions['id'];
            setPromotions([...promotions, clonedPromotions]);
        }
    };

    const openNewProductDrawer = () => setProductDrawerProperties({...newProductDrawerProps, open: true});
    const openNewBPCODrawer = () => setBPCODrawerProperties({...newBPCODrawerProps, open: true});

    const openCloneProductDialog = (productId: string) => setProductDrawerProperties({
        clone: true,
        isNewProduct: false,
        open: true,
        productId
    });

    const openCloneBPCODialog = (BPCOId: string) => setBPCODrawerProperties({
        clone: true,
        isNewBPCO: false,
        open: true,
        BPCOId
    });

    const closeProductDrawer = () => {
        setProductDrawerProperties({...newProductDrawerProps, open: false});
        onProductDrawerClose();
    }

    const closeBPCODrawer = () => {
        setBPCODrawerProperties({...newBPCODrawerProps, open: false});
        onBPCODrawerClose();
    }

    const handleProductCreated = (createdProduct: productDetailsResponseModel, extendOrOverwrite: extendOrOverwritePromotionType) => {
        if (extendOrOverwrite.extend) {
            handleProductSelect(productDetails2productSearchResultUI(createdProduct));
        } else {
            const updatePromotion: promotionUI[] = [...promotions];
            const index: number = updatePromotion.findIndex((promotion) => promotion.productId === extendOrOverwrite.overwriteProductId);
            const newPromotion: promotionUI = makePromotionOfProductSearch(productDetails2productSearchResultUI(createdProduct), mediumData?.headerData?.themeSeasons, mediumData?.headerData?.countryThemedWeeks, null, getCurrencyForCountry(mediumData?.country));

            updatePromotion[index] = {...newPromotion,
                promotionalPrice: updatePromotion[index].promotionalPrice,
                regularPrice: updatePromotion[index].regularPrice,
                relativeDiscount: updatePromotion[index].relativeDiscount,
                absoluteDiscount: updatePromotion[index].absoluteDiscount,
                mpu: updatePromotion[index].mpu,
                promotionTypes: updatePromotion[index].promotionTypes,
                themeSeasons: updatePromotion[index].themeSeasons,
                countryThemedWeeks: updatePromotion[index].countryThemedWeeks,
                qualitySeals:updatePromotion[index].qualitySeals
            };
            setPromotions(updatePromotion);
            enqueueSnackbar(`${translate({id: 'b.productAdded'})}`, {variant: 'success', persist: false});
        }
        closeProductDrawer();
    };

    const handleBPCOCreated = (createdBPCO: BPCOResponseModel, extendOrOverwrite: extendOrOverwritePromotionType) => {
        if (extendOrOverwrite.extend) {
            handleProductSelect(BPCODetails2productSearchResultUI(createdBPCO));
        } else {
            const updatePromotion: promotionUI[] = [...promotions];
            const index: number = updatePromotion.findIndex((promotion) => promotion.bpcoId === extendOrOverwrite.overwriteProductId);
            const newPromotion: promotionUI = makePromotionOfProductSearch(BPCODetails2productSearchResultUI(createdBPCO), mediumData?.headerData?.themeSeasons, mediumData?.headerData?.countryThemedWeeks, null, getCurrencyForCountry(mediumData?.country));

            updatePromotion[index] = {...newPromotion,
                promotionalPrice: updatePromotion[index].promotionalPrice,
                regularPrice: updatePromotion[index].regularPrice,
                relativeDiscount: updatePromotion[index].relativeDiscount,
                absoluteDiscount: updatePromotion[index].absoluteDiscount,
                mpu: updatePromotion[index].mpu,
                promotionTypes: updatePromotion[index].promotionTypes,
                themeSeasons: updatePromotion[index].themeSeasons,
                countryThemedWeeks: updatePromotion[index].countryThemedWeeks,
                qualitySeals:updatePromotion[index].qualitySeals
            };
            setPromotions(updatePromotion);
            enqueueSnackbar(`${translate({id: 'b.bpcoAdded'})}`, {variant: 'success', persist: false});
        }
        closeBPCODrawer();
    };

    const handlePromotionsMultiSuggestionClick = (promotionsForFrame: promotionSuggestionsForFrame[]) => {
        let derivedSeasonThemes: string[] = mediumData?.headerData?.themeSeasons;
        let derivedThemedWeeks: string[] = mediumData?.headerData?.countryThemedWeeks;
    
        const newPromotions = promotionsForFrame.flatMap((promo: promotionSuggestionsForFrame) => {
            const transformedData = transformPromotionsSuggestions(promo);
            return addDerivedLeafletValues(transformedData, derivedSeasonThemes, derivedThemedWeeks, []);
        })
    
        const updatedPromotions: promotionUI[] = [...promotions, ...newPromotions];
        setPromotions(updatedPromotions);
    };

    return (
        <>
            <LoadingOverlay show={isLoading}/>
            <PaperX>
                <ProductSearch countryId={mediumData.country}
                               onProductClick={handleProductSelect}
                               selectedItemsIds={promotions.map((item) => item.productId || item.bpcoId)}
                />
            </PaperX>
            <PaperX className="_fullHeight _fullWidth _scrollY">
                {promotions.map((item) => <Promotion countryId={mediumData.country} data={item} key={item.UIId}
                                                     onUpdatePromotion={handlePromotionUpdate}
                                                     onRemovePromotion={handlePromotionRemoval}
                                                     onPromotionError={(error, promotionId) => handlePromotionError(error, promotionId)}
                                                     onCloneProduct={(productId) => openCloneProductDialog(productId)}
                                                     onCloneBPCO={(bpcoId) => openCloneBPCODialog(bpcoId)}
                                                     onClonePromotion={handlePromotionClone}
                />)}
            </PaperX>
            <ProductDrawer {...productDrawerProperties}
                           onClose={closeProductDrawer}
                           onCreate={(newProduct, extendOrOverwrite) => handleProductCreated(newProduct, extendOrOverwrite)}
            />
            <BPCODrawer {...BPCODrawerProperties}
                        onClose={closeBPCODrawer}
                        onCreate={(newBPCO, extendOrOverwrite) => handleBPCOCreated(newBPCO, extendOrOverwrite)}
            />
            <PromotionsMultiSuggestions forceOpen={isPromotionsMultiSuggestionsOpen}
                                        onClick={(promotionsForFrame) => handlePromotionsMultiSuggestionClick(promotionsForFrame)}
                                        countryId={mediumData.country}
                                        spotId={mediumData.spotId}
            />
        </>
    );
}

export default AdvertisementBrowserEditMediaPromotions;