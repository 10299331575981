/* eslint-disable react-hooks/exhaustive-deps */
import './KeyHierarchy.scss';

import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch} from 'react-redux';
import { Link } from 'react-router-dom';
import { paths } from 'paths';
import {Button} from '@mui/material';
import {API_STATUSES} from 'config/api/constants';
import { useFormatMessage } from 'utils/translate';
import { fetchKeyHierarchy } from 'shared/reducers/keyHierarchy';
import { IRootState } from 'shared/reducers';
import {bannerObject, keyAccount, keyAccountObject, retailerObject} from 'shared/models/keyAccount.model';
import {specialRoles} from 'shared/models/permissions.model';
import {retailer} from 'shared/models/retailer.model';
import {banner} from 'shared/models/banner.model';
import {keyAccountHierarchyResponseModel} from 'shared/models/hierarchy.model';
import Footer from 'components/Footer';
import {PaperX} from 'components/PaperX';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import {ButtonClose, ButtonEdit, ButtonNewWhite} from 'components/Buttons';
import {HierarchyDialogKeyAccount, keyAccountTemplate} from './HierarchyDialogKeyAccount';
import {HierarchyDialogRetailer, retailerTemplate} from './HierarchyDialogRetailer';
import KeyHierarchyFilters from './KeyHierarchyFilters';
import {bannerTemplate, HierarchyDialogBanner} from './HierarchyDialogBanner';

const minSearchStrLen: number = 1;
const returnPath: string = paths.keyHierarchy;

const KeyRetailerBannerHierarchy = () => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();

    const hierarchyStatus = useSelector((state: IRootState) => state.keyHierarchy.status);
    const hierarchyData = useSelector((state: IRootState) => state.keyHierarchy.data);
    const userRoles = useSelector((state: IRootState) => state.userPermissions.specialRoles);

    const [filteredData, setFilteredData] = useState<keyAccountHierarchyResponseModel>([]);
    const [keyAccounts, setKeyAccounts] = useState<keyAccountObject[]>([]); // keyAccountObject[] equals keyAccountHierarchyResponseModel but used for readability
    const [retailers, setRetailers] = useState<retailerObject[]>([]);
    const [banners, setBanners] = useState<bannerObject[]>([]);

    const [selectedKeyAccountName, setSelectedKeyAccountName] = useState<string>('');
    const [selectedKeyAccountId, setSelectedKeyAccountId] = useState<string>(null);
    const [selectedRetailerName, setSelectedRetailerName] = useState<string>('');
    const [selectedRetailerId, setSelectedRetailerId] = useState<string>(null);

    const isAllowed: boolean = userRoles.includes(specialRoles.RETAILER_HIERARCHY_MANAGEMENT);

    const [countryFilter, setCountryFilter] = useState<string>('');

    const [searchString, setSearchString] = useState<string>('');

    const buildKeyAccountsList = (): JSX.Element[] => keyAccounts.map((item) =>
        (<li className={selectedKeyAccountId === item.id ? "hierarchyItem selected" : "hierarchyItem"} key={item.id} onClick={() => handleKeyAccountClick(item)}>
            <span>{item.name}</span>
            {isAllowed && <ButtonEdit onClick={() => openKeyAccountDialog(item)}/>}
        </li>));
    const buildRetailersList = (): JSX.Element[] => retailers.map((item) =>
        (<li className={selectedRetailerId === item.id ? "hierarchyItem selected" : "hierarchyItem"} key={item.id} onClick={() => handleRetailerClick(item)}>
            <span>{item.name}</span>
            {isAllowed && <ButtonEdit onClick={() => openRetailerDialog(item)}/>}
        </li>));
    const buildBannersList = (): JSX.Element[] =>  banners.map((item) =>
        (<li className="hierarchyItem" style={{ textDecoration: 'none' }} key={item.id}>
            <span>{item.name}</span>
            {isAllowed && 
                <div className="actionButtonContainer">
                    <Link to={`${paths.branchesList}?id=${item.id}&from=${returnPath}`}>
                        <Button size="small" variant="outlined" color="secondary">
                            <FormattedMessage id="b.branches"/>
                        </Button>
                    </Link>
                    <ButtonEdit onClick={() => openBannerDialog(item)}/>
                </div>}
        </li>))

    const keyAccountsList = useMemo(buildKeyAccountsList, [keyAccounts, selectedKeyAccountId]);

    const [dialogKeyAccountOpen, setDialogKeyAccountOpen] = useState<boolean>(false);
    const [dialogKeyAccountData, setDialogKeyAccountData] = useState<keyAccount>(keyAccountTemplate);
    const [dialogRetailerOpen, setDialogRetailerOpen] = useState<boolean>(false);
    const [dialogRetailerData, setDialogRetailerData] = useState<retailer>(retailerTemplate);
    const [dialogBannerOpen, setDialogBannerOpen] = useState<boolean>(false);
    const [dialogBannerData, setDialogBannerData] = useState<banner>(bannerTemplate);

    useEffect(() => {
        if (hierarchyStatus === API_STATUSES.ERROR) {
            dispatch(fetchKeyHierarchy());
        }
    }, []);

    useEffect(() => {
        if (hierarchyStatus === API_STATUSES.NEW) {
            dispatch(fetchKeyHierarchy());
        }
    }, [hierarchyStatus]);

    useEffect(() => {
        applyFilters(hierarchyData);
        if (selectedKeyAccountId) {
            const focusOn = hierarchyData.find((item) => item.id === selectedKeyAccountId);
            setSelectedKeyAccountName(focusOn.name);
        }
    }, [hierarchyData]);

    useEffect(() => {
        setKeyAccounts(filteredData);
    }, [filteredData]);

    useEffect(() => {
        setRetailers([]);
    },[keyAccounts]);

    // clear child lists when parent changes
    useEffect(() => {
        if (selectedRetailerId === null) {
            setBanners([]);
        }
    },[retailers]);

    // clear child lists when parent changes
    useEffect(() => {
        cleanup();
    },[countryFilter, searchString]);

    const cleanup = () => {
        setSelectedKeyAccountId(null);
        setSelectedKeyAccountName('');
        setSelectedRetailerId(null);
        setSelectedRetailerName('');
        applyFilters(hierarchyData);
    }

    const applyFilters = (data: keyAccountHierarchyResponseModel) => {
        let byCountry: keyAccountObject[] = [] ;
        if (countryFilter !== translate({id: 'a.any'})){
            data.forEach((keyAccount) => {
                let filteredRetailers: retailerObject[] = [];
                keyAccount.retailers.forEach((retailer) => {
                    let filteredBanners: bannerObject[] = [];
                    retailer.banners.forEach((banner) => {
                        if(banner.countryId === countryFilter) {
                            filteredBanners.push(banner);
                        }
                    });
                    if (filteredBanners.length > 0) {
                        filteredRetailers.push(retailer);
                    }
                });
                if(filteredRetailers.length > 0) {
                    byCountry.push(keyAccount);
                }
            });
        } else {
            byCountry = data;
        }

        if (searchString.length > minSearchStrLen) {
            let byString: keyAccountHierarchyResponseModel = [];
            byCountry.forEach((keyAcc) => {
                if (keyAcc.name.toLowerCase().indexOf(searchString) > -1) {
                    byString.push(keyAcc);
                } else {
                    const filteredRetailers: retailerObject[] = [];
                    keyAcc.retailers.forEach((retailer) => {
                        if (retailer.name.toLowerCase().indexOf(searchString) > -1) {
                            filteredRetailers.push(retailer);
                        } else {
                            const filteredBanners: bannerObject[] = [];
                            retailer.banners.forEach((banner) => {
                                if (banner.name.toLowerCase().indexOf(searchString) > -1) {
                                    filteredBanners.push(banner);
                                }
                            });
                            if (filteredBanners.length) {
                                filteredRetailers.push({...retailer, banners: filteredBanners});
                            }
                        }
                    });
                    if (filteredRetailers.length) {
                        byString.push({...keyAcc, retailers: filteredRetailers});
                    }
                }
            });
            setFilteredData(byString);
        } else {
            setFilteredData(byCountry);
        }
    };

    const handleKeyAccountClick = (item: keyAccountObject) => {
        setRetailers(item.retailers);
        setSelectedKeyAccountName(item.name);
        setSelectedKeyAccountId(item.id);
        if (item.retailers[0]) {
            handleRetailerClick(item.retailers[0]);
        } else {
            setSelectedRetailerName('');
            setSelectedRetailerId('');
            setBanners([]);
        }
    };

    const handleRetailerClick = (item: retailerObject) => {
        setBanners(item.banners);
        setSelectedRetailerName(item.name);
        setSelectedRetailerId(item.id);
    };

    const handleCountryChange = (country: string) => setCountryFilter(country);

    const handleSearchStringChange = (value: string) => setSearchString(value.toLowerCase());

    const openKeyAccountDialog = (data: keyAccountObject = null) => {
        if (data) {
            const {active, id, name} = data;
            const dialogKeyAccountData: keyAccount = {active, id, name};
            setDialogKeyAccountData(dialogKeyAccountData);
        } else {
            setDialogKeyAccountData(keyAccountTemplate);
        }
        setDialogKeyAccountOpen(true);
    };

    const openRetailerDialog = (data: retailerObject = null) => {
        if (data) {
            const {active, id, name} = data;
            const dialogRetailerData: retailer = {active, id, keyAccountId: selectedKeyAccountId, name}
            setDialogRetailerData(dialogRetailerData);
        } else {
            setDialogRetailerData({...retailerTemplate, keyAccountId: selectedKeyAccountId});
        }
        setDialogRetailerOpen(true);
    };

    const openBannerDialog = (data: bannerObject = null) => {
        if (data) {
            const {active, countryId, id, isGross, name, publishWithValidity} = data;
            const dialogBannerData: banner = {active, countryId, id, isGross, industryId: '', name, publishWithValidity, retailPartner: false, retailerId: selectedRetailerId};
            setDialogBannerData(dialogBannerData);
        } else {
            setDialogBannerData({...bannerTemplate, retailerId: selectedRetailerId});
        }
        setDialogBannerOpen(true);
    };

    const handleKeyAccountDialogClose = (reload:boolean) => {
        setDialogKeyAccountOpen(false);
        if (reload) {
            cleanup();
            dispatch(fetchKeyHierarchy());
        }
    };

    const handleRetailerDialogClose = (reload:boolean) => {
        setDialogRetailerOpen(false);
        if (reload) {
            cleanup();
            dispatch(fetchKeyHierarchy());
        }
    };

    const handleBannerDialogClose = (reload:boolean) => {
        setDialogBannerOpen(false);
        if (reload) {
            cleanup();
            dispatch(fetchKeyHierarchy());
        }
    };

    return (
        <div className="viewRoot keyRetailerBannerHierarchyRoot">
            <div className="viewport">
                <div className="viewContainer _directionRow">
                    <LoadingOverlay show={hierarchyStatus === API_STATUSES.PENDING}/>
                    <KeyHierarchyFilters onCountryChange={handleCountryChange} onSearchStringChange={handleSearchStringChange}/>
                    <div className="hierarchyLevelContainer _directionCol">
                        <PaperX className="weldBottom">
                            <div className="_header">
                                <FormattedMessage id='hierarchy.keyAccounts'/>
                                {isAllowed && <ButtonNewWhite onClick={() => openKeyAccountDialog()}/>}
                            </div>
                        </PaperX>
                        <PaperX className="listContainer _scrollY _fullHeight weldTop">
                            {keyAccounts.length > 0 ?
                                <ul>{keyAccountsList}</ul>
                                :
                                <FormattedMessage id="hierarchy.emptyList"/>
                            }
                        </PaperX>
                    </div>
                    <div className="hierarchyLevelContainer _directionCol">
                        <PaperX className="weldBottom">
                            <div className="_header">
                                <FormattedMessage id='hierarchy.retailers' values={{keyAccountName: selectedKeyAccountName}}/>
                                {isAllowed && <ButtonNewWhite onClick={() => openRetailerDialog()}/>}
                            </div>
                        </PaperX>
                        <PaperX className="listContainer _scrollY _fullHeight weldTop">
                            {retailers.length > 0 ?
                                <ul>{buildRetailersList()}</ul>
                                :
                                <FormattedMessage id="hierarchy.emptyList"/>
                            }
                        </PaperX>
                    </div>
                    <div className="hierarchyLevelContainer _directionCol">
                        <PaperX className="weldBottom">
                            <div className="_header">
                                <FormattedMessage id='hierarchy.banners' values={{retailerName: selectedRetailerName}}/>
                                {isAllowed && <ButtonNewWhite onClick={() => openBannerDialog()}/>}
                            </div>
                        </PaperX>
                        <PaperX className="listContainer _scrollY _fullHeight weldTop">
                            {banners.length > 0 ?
                                <ul>{buildBannersList()}</ul>
                                :
                                <FormattedMessage id="hierarchy.emptyList"/>
                            }
                        </PaperX>
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
            <HierarchyDialogKeyAccount open={dialogKeyAccountOpen}
                                       onClose={(reload) => handleKeyAccountDialogClose(reload)}
                                       keyAccount={dialogKeyAccountData}
            />
            <HierarchyDialogRetailer open={dialogRetailerOpen}
                                     onClose={(reload) => handleRetailerDialogClose(reload)}
                                     retailer={dialogRetailerData}
            />
            <HierarchyDialogBanner open={dialogBannerOpen}
                                   onClose={(reload) => handleBannerDialogClose(reload)}
                                   banner={dialogBannerData}
            />
        </div>
    );
};

export default KeyRetailerBannerHierarchy;