import './ProductDetails.scss';
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Button, Divider, Tooltip} from '@mui/material';
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material';
import {ArrowForward} from '@mui/icons-material';
import {
    API_GET_PRODUCT_DETAILS,
    API_PRODUCT_DATA_GET_DESCRIPTION,
    API_STATUSES
} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {slimAttributeResponseModel} from 'shared/models/attribute.model';
import {buildProductTemplate, productPayload, UIProductModel} from 'shared/models/product.model';
import {gtinObject} from 'shared/models/gtin.model';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {checkGtin} from 'utils/gtinsValidationUtils';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import {Attribute, isAttributeValid} from 'components/Attribute';
import ActiveCheckbox from 'components/ActiveCheckbox';
import BrandSearch from 'modules/MasterData/Brand/BrandSearch';
import {useBrickAttributesQuery} from 'modules/MasterData/Category/categoryAPI';
import {attrTypes} from 'modules/Dictionaries/Attributes/Attribute';
import {transformForBE, transformForUI} from 'modules/MasterData/Product/productIO';
import Gtins from 'modules/MasterData/Product/Gtins/Gtins';
import {productDescriptionResponseModel, productDescriptionUI} from 'shared/models/productDescription.model';
import {basic} from 'shared/models/_common.model';
import {basicSupplierWithUsageCount} from 'shared/models/supplier.model';
import {transformDescriptionForUI} from 'modules/MasterData/ProductDescription/productDescriptionIO';
import IconButton from '@mui/material/IconButton';
import ProductDescriptionDialog from 'modules/MasterData/ProductDescription/ProductDescriptionDialog';
import ProductDescriptionCopyDialog from 'modules/MasterData/ProductDescription/ProductDescriptionCopyDialog';
import {handyAttributes} from 'shared/handyAttributes';
import ProductPreview from 'components/gfx/ProductPreview/ProductPreview';
import {MarketDisplay} from 'components/Displays';
import SupplierDisplay from 'components/Displays/SupplierDisplay';
import ProductDescriptionDisplay from 'components/Displays/ProductDescriptionDisplay';
import EditIcon from '@mui/icons-material/Edit';
import {
    ProductIssuesDisplay,
    ResolveIssueDialog
} from 'components/ReportProduct';
import SupplierDialog from './SupplierDialog';
import {paths} from 'paths';
import SelectDescriptionDialog from './SelectDescriptionDialog';
import {useFormatMessage} from 'utils/translate';
import BrandNewDialog from './BrandNewDialog/BrandNewDialog';
import StructuredBrandNewDialog from './StructuredBrandNewDialog/StructuredBrandNewDialog';

interface productDetailsProps {
    clone: boolean,
    isDescribingFrame?: boolean // set true when need to alter layout for frame description view
    isNewProduct: boolean,
    onProductChange: (product: productPayload, isProductValid: boolean, hasBeenReplaced: boolean) => void,
    productId: string
}

const ProductDetails = (props: productDetailsProps) => {
    const translate = useFormatMessage();
    const {clone, isDescribingFrame, isNewProduct, onProductChange, productId} = props;
    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const productAPIGet = useApi('get', null, {errMsg: 'product.err'});
    const productDescriptionAPIGet = useApi('get', null, {errMsg: 'productDescription.err'});

    const [product, setProduct] = useState<UIProductModel>(buildProductTemplate(userProfile.countryMarket.market));

    const [originProduct, setOriginProduct] = useState<UIProductModel>(buildProductTemplate(userProfile.countryMarket.market));

    const [categoryCharacteristics, setCategoryCharacteristics] = useState({});

    const [categoryCharacteristicsData, setCategoryCharacteristicsData] = useState<slimAttributeResponseModel[]>([]);

    const brickAttributesQuery = useBrickAttributesQuery(product.category.id, userProfile.langData, product.category.id !== '');

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [isForceFirstAutoselect, setIsForceFirstAutoselect] = useState<boolean>(false);

    const [deprecatedAttributeIds, setDeprecatedAttributeIds] = useState<string[]>([]);

    const [mandatoryTable, setMandatoryTable] = useState({});

    const [createNewProductDescriptionDialogOpen, setCreateNewProductDescriptionDialogOpen] = useState<boolean>(false);
    const [productDescriptionCopyDialogOpen, setProductDescriptionCopyDialogOpen] = useState<boolean>(false);
    const [supplierDialogOpen, setSupplierDialogOpen] = useState<boolean>(false);
    const [selectDescriptionDialogOpen, setSelectDescriptionDialogOpen] = useState<boolean>(false);
    const [selectedBrickId, setSelectedBrickId] = useState<string>('');

    const [brandAndBrickChangeCounter, setBrandAndBrickChangeCounter] = useState<number>(0);
    const [suppliersQty, setSuppliersQty] = useState<number>(0);

    const [isImgSectionExpanded, setIsImgSectionExpanded] = useState<boolean>(false);
    const [isBrandNewDialogOpen, setIsBrandNewDialogOpen] = useState<boolean>(false);
    const [isStructuredBrandNewDialogOpen, setIsStructuredBrandNewDialogOpen] = useState<boolean>(false);
    const [isResolveIssuesDialogOpen, setIsResolveIssuesDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (productId) {
            productAPIGet.call(API_GET_PRODUCT_DETAILS(productId, userProfile.langData))
        }
    }, [productId]);

    useEffect(() => {
        if (!isFirstLoad){
           onProductChange(transformForBE(product, categoryCharacteristics), canSave(), hasBeenReplaced());
        }
    }, [product, categoryCharacteristics, isFirstLoad]);

    useEffect(() => {
        if (suppliersQty > 1 && ((isForceFirstAutoselect && productDescriptionAPIGet.status === API_STATUSES.PENDING) || brandAndBrickChangeCounter) && selectedBrickId && product.brand) {
            setSupplierDialogOpen(true);
        }
    }, [isForceFirstAutoselect, brandAndBrickChangeCounter, suppliersQty, selectedBrickId, productDescriptionAPIGet.status]);

    const canSave = ():boolean => {
        if (!areCharacteristicsValid()) {
            return false;
        }
        const hasBrand = product.brand && product.brand.id;
        const hasProductDescription = product.productDescriptionId;
        return hasBrand && hasProductDescription && areGtinsValid() && !compareGtinsInProduct();
    };

    const hasBeenReplaced = (): boolean => !!product.replacementProductId;

    useEffect(() => {
        return () => {
            productAPIGet.clearToken();
        }
    }, []);

    useEffect(() => {
        if (productAPIGet.data){
            let productData: UIProductModel = transformForUI(productAPIGet.data);
            if (productData) {
                if (clone){
                    if (productAPIGet.data.isTemplate){
                        handleProductsProcess(productData);
                    }else {
                        productData = {...productData, gtins: []};
                        handleProductsProcess(productData);
                    }
                }else {
                    handleProductsProcess(productData);
                }
            }
        }
    }, [productAPIGet.data]);

    const handleProductsProcess = (productData: UIProductModel) => {
        setProduct(productData);
        setOriginProduct(productData);
        setIsFirstLoad(false);
    };

    useEffect(() => {
        if (product.productDescriptionId){
            productDescriptionAPIGet.call(API_PRODUCT_DATA_GET_DESCRIPTION(product.productDescriptionId, userProfile.langData));
        }
    },[product.productDescriptionId])

    useEffect(() => {
        if (productDescriptionAPIGet.data){
            const resp: productDescriptionResponseModel = productDescriptionAPIGet.data;
            let productDescriptionData: productDescriptionUI = transformDescriptionForUI(resp);
            const updateProduct = {...product};
            if (productDescriptionData) {
                updateProduct['category'] = productDescriptionData.category;
                setProduct(updateProduct);
            }
            setSelectedBrickId(productDescriptionAPIGet.data.brickId);
            setIsFirstLoad(false);
        }
    }, [productDescriptionAPIGet.data]);

    useEffect(() => {
        if (brickAttributesQuery.data) {
            if (Array.isArray(brickAttributesQuery.data?.attributes)) {
                const attributesId = [];
                brickAttributesQuery.data.attributes.forEach((item) => {
                    if (product.characteristics[item.id] === undefined) attributesId.push(item.id); // to be sure that we don't consume duplicate attributes
                });
                setCategoryCharacteristicsData(brickAttributesQuery.data.attributes);
                setCategoryCharacteristics(attributesId.reduce((acc, val) => ({...acc, [val]:null}), {}));
                for (let attribute in brickAttributesQuery.data.attributes){
                    handleCharacteristicReady(brickAttributesQuery.data.attributes[attribute]);
                }
            }
        } else {
            console.error('error fetching category characteristics')
            setCategoryCharacteristicsData([]);
            setCategoryCharacteristics({});
        }
    }, [brickAttributesQuery.data])

    useEffect(() => {
        if (originProduct.characteristics && categoryCharacteristicsData.length !== 0 && !isNewProduct) {
            sortProductCharacteristics(categoryCharacteristicsData);
        }
    }, [originProduct, categoryCharacteristicsData]);

    const handleResolveSuccess = () => {
        setIsResolveIssuesDialogOpen(false);
        const newProduct: UIProductModel = {...product};
        newProduct.comments = [];
        setProduct(newProduct);
    };

    const sortProductCharacteristics = (brickCharacteristics) => {
        const deprecatedIds = [];
        const mappedCharacteristicIds = brickCharacteristics.map((index) => index.id);
        let sortedAttributeCharacteristics = mappedCharacteristicIds.reduce((acc,val) => ({...acc, [val]: null}), {});
        for (let id in originProduct.characteristics){
            if(id !== handyAttributes.description && !mappedCharacteristicIds.includes(id)){
                deprecatedIds.push(id);
            }
        }
        for (let newId in mappedCharacteristicIds){
            if (originProduct.characteristics[mappedCharacteristicIds[newId]]){
                sortedAttributeCharacteristics[mappedCharacteristicIds[newId]] = originProduct.characteristics[mappedCharacteristicIds[newId]];
            }
        }
        for (let ghostIds in deprecatedIds){
            sortedAttributeCharacteristics[deprecatedIds[ghostIds]] = originProduct.characteristics[deprecatedIds[ghostIds]];
        }
        product.characteristics = sortedAttributeCharacteristics;
        setDeprecatedAttributeIds(deprecatedIds);
    };

    const handleProductCharacteristicChange = (key, value) => {
        const newProduct = {...product};
        if (deprecatedAttributeIds.includes(key)){
            delete newProduct.characteristics[key];
        }else newProduct.characteristics[key] = value;
        setProduct(newProduct);
    };

    const handleCharacteristicReady = (data: slimAttributeResponseModel) => {
        const newMandatoryTable = mandatoryTable;
        if (data?.id && mandatoryTable[data?.id] === undefined) {
            if (data.id === handyAttributes.contentOfTradingUnit) { // contentOfTradingUnit is mandatory
                newMandatoryTable[data.id] = true;
            } else {
                newMandatoryTable[data.id] = data.type === attrTypes.ENUMERATION || data.type === attrTypes.BRICK_DEPENDENT_ENUMERATION;
            }
        }
        setMandatoryTable(newMandatoryTable);
    };

    const handleCategoryCharacteristicChange = (key, value) => {
        const newCategoryCharacteristics = {...categoryCharacteristics};
        newCategoryCharacteristics[key] = value;
        setCategoryCharacteristics(newCategoryCharacteristics);
    };

    const handleBrandSelect = (brand: basic) => {
        setSuppliersQty(0);
        setBrandAndBrickChangeCounter(brandAndBrickChangeCounter+1);
        setProduct({...product, brand, supplierId: '', supplierName: ''});
    };
   
    const handleActiveChange = (key, value) => setProduct({...product, active: value});

    const handleGtinRemoval = (gtin: string) => {
        const updatedGtins: gtinObject[] = product.gtins.filter((item) => item.value !== gtin);
        setProduct({ ...product, gtins: updatedGtins });
    };

    const handleGtinAdd = (gtin: string) => {
        const newGtins: gtinObject[] =  [...product.gtins];
        newGtins.push({value: gtin, active: true});
        setProduct({ ...product, gtins: newGtins });
    };

    const handleGtinActiveToggle = (gtin: string) => {
        const newGtins: gtinObject[] = [...product.gtins];
        const gtin2toggleIndex: number = newGtins.findIndex((item) => item.value === gtin);
        newGtins[gtin2toggleIndex].active = !product.gtins[gtin2toggleIndex].active;
        setProduct({ ...product, gtins: newGtins });
    }

    const showLoading = () => productAPIGet.status === API_STATUSES.PENDING || brickAttributesQuery.isLoading ||
        productDescriptionAPIGet.status === API_STATUSES.PENDING;

    const areCharacteristicsValid = () => {
        if (isNewProduct){
            for (let id in categoryCharacteristics) {
                if (id === null) return false; // @TODO consider removing, most probably an obsolote code when characteristics were structurized way different
                if (mandatoryTable[id] !== false && !isAttributeValid(categoryCharacteristics[id])) return false;
            }
        }else {
            for (let id in product.characteristics) {
                if (id === null) return false; // @TODO consider removing, most probably an obsolote code when characteristics were structurized way different
                if (mandatoryTable[id] !== false && !isAttributeValid(product.characteristics[id])) return false;
            }
        }
        return true;
    };

    const areGtinsValid = () => {
        let isValid = true;
        for (let id in product.gtins) {
            if (checkGtin(product.gtins[id].value) === false) {
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const compareGtinsInProduct = () => {
        const gtinsValues = {};
        for (let i = 0; i < product.gtins.length; i++) {
            const value = product.gtins[i].value;
            if (gtinsValues[value]) {
                return true;
            }
            gtinsValues[value] = true;
        }
        return false;
    };

    // when creating new product - these are characteristics from currently selected category
    // when editing - they are now product characteristics so this list is empty and they're displayed by buildProductCharacteristicsList function
    // if you change category during edit, old characteristics will remain (as a characteristics of a product), and characteristics of newly selected category are rendered here
    const buildCategoryCharacteristicsList = (characteristicSet) => {
        const uiElements = [];
        for (let id in characteristicSet){
            uiElements.push(<div className="_formRow" key={id}>
                <div>
                    <Attribute data={categoryCharacteristics[characteristicSet[id].id]}
                               newData={characteristicSet[id]}
                               onChange={handleCategoryCharacteristicChange}
                               required={isRequired(characteristicSet[id].id)}/>
                </div>
            </div>);
        }
        return uiElements;
    };

    const isRequired = (attribtueId: string):boolean => mandatoryTable[attribtueId];

    const buildCharacteristicsHeader = (characteristicsSet, label: string, len:number) => {
        //as long as description is part of characteristic, not separate property, we need to check if len is 0 or 1 for product characteristics and 0 for categoryCharacteristics
        if(characteristicsSet && Object.keys(characteristicsSet).length <= len) {
            return null;
        }
        return <div className="_formRow">
            <div className="_header">
                <FormattedMessage id={label}/>
            </div>
        </div>;
    }
    // rendering product characteristics, those derived from category, exclude description until it is separate product property
    const buildProductCharacteristicsList = () => {
        const uiElements = [];
        for (let id in product.characteristics) {
            if (!deprecatedAttributeIds.includes(id)){
                uiElements.push(<div className="_formRow" key={id}>
                        <div key={`${id}-key`}>
                            <Attribute data={product.characteristics[id]}
                                       newData={categoryCharacteristicsData.find((index) => index.id === id)}
                                       onChange={handleProductCharacteristicChange}
                                       required={isRequired(id)}/>
                        </div>
                    </div>
                );
            }
        }
        return uiElements;
    };

    const buildDeprecatedAttributes = () => {
        const uiElements = [];
        for (let id in product.characteristics) {
            if (deprecatedAttributeIds.includes(id)) {
                uiElements.push(<div className="_formRow" key={id}>
                        <div>
                            <Attribute id={id}
                                       data={product.characteristics[id]}
                                       onChange={handleProductCharacteristicChange}
                                       required={false}
                                       disabled={true}/>
                        </div>
                    </div>
                );
            }
        }
        return uiElements;
    };

    const handleProductDescriptionSave = (productDescriptionId: string) => {
        const updateProduct = {...product};
        updateProduct['productDescriptionId'] = productDescriptionId;
        setProduct(updateProduct);
        setCreateNewProductDescriptionDialogOpen(false);
        setProductDescriptionCopyDialogOpen(false);
    }

    const handleSupplierChange = (supplier: basicSupplierWithUsageCount, suppliersQty: number) => {
        const newProduct: UIProductModel = {...product};
        newProduct.supplierId = supplier?.id;
        newProduct.supplierName = supplier?.name;
        if (newProduct.supplierId || newProduct.supplierName) setProduct(newProduct);
        setSuppliersQty(suppliersQty);
        setSupplierDialogOpen(false);
    };

    const handleSupplierRemove = () => {
        const newProduct: UIProductModel = {...product};
        newProduct.supplierId = '';
        newProduct.supplierName = '';
        setProduct(newProduct);
        setSupplierDialogOpen(false);
    };

    const handleDescriptionSelect = (descriptionId: string) => {
        if (!isNewProduct) setIsForceFirstAutoselect(true);
        setSelectedBrickId(null);
        setSelectDescriptionDialogOpen(false);
        setProduct({...product, productDescriptionId: descriptionId});
    };

    const handleOpenNewBrandDialog = () => {
        setIsBrandNewDialogOpen(true);
    }

    const handleOpenstructuredNewBrandDialog = () => {
        setIsStructuredBrandNewDialogOpen(true);
    }

    return (<>
                <LoadingOverlay show={showLoading()}/>
                <div className="viewContainer _directionRow productDetailsRoot">
                    <PaperX className="_fullHeight _fullWidth _scrollY">
                        <div className="_header">
                            <FormattedMessage id={clone ? 'b.productCopy' : 'b.product'}/>
                        </div>
                        {!clone &&
                            <ProductIssuesDisplay comments={product?.comments}
                                              onResolveClick={() => setIsResolveIssuesDialogOpen(true)}
                            />
                        }
                        {
                            hasBeenReplaced() &&
                                <div className="productReplacedMsgBox">
                                    <Button onClick={() => window.location.href = `${paths.productDetails}?id=${product.replacementProductId}`}
                                            endIcon={<ArrowForward/>}>
                                        <FormattedMessage id="product.succReplaced"/>
                                    </Button>
                                </div>
                        }
                        <div className="_formRow">
                            <MarketDisplay marketId={product.marketId}/>
                        </div>
                        <div className="spacer"></div>
                        <div className="_formRow">
                            <div>
                                <BrandSearch onBrandSelect={handleBrandSelect} data={product.brand} queryParams={{marketId: product.marketId, active: true}} required/>
                            </div>
                        </div>
                        {!isNewProduct && !isDescribingFrame && !clone && 
                            <div className="_formRow">
                                    <div className="imgSectionContainer">
                                        <Button color="secondary"
                                                variant="outlined"
                                                endIcon={isImgSectionExpanded ? <ArrowDropUp /> : <ArrowDropDown/>}
                                                onClick={() => setIsImgSectionExpanded(!isImgSectionExpanded)}>
                                                {translate({id: isImgSectionExpanded ? 'product.hideImages' : 'product.showImages'})}
                                        </Button>
                                        {isImgSectionExpanded && <ProductPreview productId={productId} isProductDetailsView/>}
                                    </div>
                            </div>
                        }
                        {isDescribingFrame && 
                            <div className="newBrandWrapper">
                                <Button variant="outlined" color="primary" size="small" className="newBrandBtn" onClick={handleOpenNewBrandDialog}>
                                    <FormattedMessage id="brand.create"/>
                                </Button>
                                <Button variant="outlined" color="primary" size="small" onClick={handleOpenstructuredNewBrandDialog}>
                                    <FormattedMessage id="brand.createStructured"/>
                                </Button>
                            </div>}
                        <div className="_formRow">
                            <div className="descriptionContainer">
                                {product.productDescriptionId ?
                                    <ProductDescriptionDisplay data={productDescriptionAPIGet.data} status={productDescriptionAPIGet.status}/>
                                    :
                                    <div className="required" onClick={() => setSelectDescriptionDialogOpen(true)}>
                                        <FormattedMessage id="b.descr"/> <span><FormattedMessage id="v.requiredIsEmpty"/></span>*
                                    </div>
                                }
                                <div className="actionWrapper">
                                    <Button variant="outlined" size="small" color="primary" onClick={() => setSelectDescriptionDialogOpen(true)}>
                                        <FormattedMessage id='product.chooseDescription'/>
                                    </Button>
                                    <Tooltip title={translate({id: 'productDescription.createCopy'})}>
                                        <Button variant="outlined" size="small" color="secondary"
                                                disabled={!product.productDescriptionId}
                                                onClick={() => setProductDescriptionCopyDialogOpen(true)}>
                                            <FormattedMessage id='a.copy'/>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={translate({id: 'productDescription.createNew'})}>
                                        <Button variant="outlined" size="small" color="secondary" onClick={() => setCreateNewProductDescriptionDialogOpen(true)}>
                                            <FormattedMessage id='a.new'/>
                                        </Button>
                                    </Tooltip>
                                </div>
                                <div className="_formRow supplierRow">
                                    <SupplierDisplay name={product.supplierName}/>
                                    <IconButton color="secondary" onClick={() => setSupplierDialogOpen(true)} disabled={!product?.brand?.id}>
                                        <EditIcon/>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <div className="isProductActive">
                            <ActiveCheckbox tabIndex={-1} value={product.active} onChange={handleActiveChange}/>
                        </div>
                        <Divider/>
                        <Gtins brand={product.brand} category={product.category} description={product.mainDescriptions}
                               gtins={product?.gtins}
                               isDescribingFrame={isDescribingFrame}
                               onAdd={(gtin) => handleGtinAdd(gtin)}
                               onActiveToggle={(gtin) => handleGtinActiveToggle(gtin)}
                               onDelete={(gtin) => handleGtinRemoval(gtin)}
                        />
                        <Divider/>
                    </PaperX>
                    <PaperX className="_fullHeight _fullWidth _scrollY">
                        {!isNewProduct ?
                            <div className="_fullWidth _fullHeight _scrollY">
                                {deprecatedAttributeIds.length !== 0 ?
                                    <>
                                        <PaperX className="weldBottom">
                                            {buildCharacteristicsHeader(product.characteristics, 'b.characteristics', 1)}
                                            {buildProductCharacteristicsList()}
                                        </PaperX>
                                        <PaperX className="weldTop">
                                            <Divider className="separator"/>
                                            {buildCharacteristicsHeader(product.characteristics, 'b.deprecatedCharacteristics', 1)}
                                            {buildDeprecatedAttributes()}
                                        </PaperX>
                                    </>
                                    :
                                    <>
                                        <PaperX className="_fullWidth _fullHeight _scrollY">
                                            {buildCharacteristicsHeader(product.characteristics, 'b.characteristics', 1)}
                                            {buildProductCharacteristicsList()}
                                        </PaperX>
                                    </>
                                }
                            </div>
                            :
                            <PaperX className="_fullHeight _fullWidth _scrollY">
                                <>
                                    {buildCharacteristicsHeader(categoryCharacteristicsData, 'b.categoryCharacteristics', 0)}
                                    {buildCategoryCharacteristicsList(categoryCharacteristicsData)}
                                </>
                            </PaperX>
                        }
                    </PaperX>
                </div>
            <ProductDescriptionDialog
                           clone={clone}
                           isCreatingNewDescription={createNewProductDescriptionDialogOpen}
                           isDescribingFrame={isDescribingFrame}
                           onCreate={(productDescriptionId: string) =>  handleProductDescriptionSave(productDescriptionId)}
                           onClose={() => setCreateNewProductDescriptionDialogOpen(false)}
                           open={createNewProductDescriptionDialogOpen}
            />
            <ProductDescriptionCopyDialog copyDescriptionId={product?.productDescriptionId}
                                          isDescribingFrame={isDescribingFrame}
                                          onCopy={(productDescriptionId: string) => handleProductDescriptionSave(productDescriptionId)}
                                          onClose={() => setProductDescriptionCopyDialogOpen(false)}
                                          open={productDescriptionCopyDialogOpen}/>
            <SelectDescriptionDialog category={product.category}
                                     isDescribingFrame={isDescribingFrame}
                                     marketId={product.marketId}
                                     open={selectDescriptionDialogOpen}
                                     onClose={() => setSelectDescriptionDialogOpen(false)}
                                     onSelect={(descriptionId) => handleDescriptionSelect(descriptionId)}
                                     showNav={!isDescribingFrame}
            />
            <SupplierDialog brand={product?.brand}
                            brickId={selectedBrickId}
                            currentSupplierId={product?.supplierId}
                            isDescribingFrame={isDescribingFrame}
                            onClose={() => setSupplierDialogOpen(false)}
                            onRemove={() => handleSupplierRemove()}
                            onChange={(supplier, suppliersQty) => handleSupplierChange(supplier, suppliersQty)}
                            open={supplierDialogOpen}
                            brandAndBrickChangeCounter={brandAndBrickChangeCounter}
            />
            <ResolveIssueDialog comments={product?.comments}
                                onClose={() => setIsResolveIssuesDialogOpen(false)}
                                onResolveSuccess={handleResolveSuccess}
                                open={isResolveIssuesDialogOpen}
                                productId={productId}
            />
            {isDescribingFrame &&
                <>
                    <BrandNewDialog open={isBrandNewDialogOpen}
                                    onSelect={handleBrandSelect}
                                    onClose={() => setIsBrandNewDialogOpen(false)}/>
                    <StructuredBrandNewDialog open={isStructuredBrandNewDialogOpen}
                                            onSelect={handleBrandSelect}
                                            onClose={() => setIsStructuredBrandNewDialogOpen(false)}/>
                </>}
        </>
    );
};

export default ProductDetails;

