import {
    productDescriptionPayload,
    productDescriptionResponseModel,
    productDescriptionUI,
    productDescriptionUpdatePayload,
} from 'shared/models/productDescription.model';
import { categoryType} from 'shared/models/category.model';
import { v4 as uuid } from 'uuid';

export const transformForBE = (description: productDescriptionUI): productDescriptionPayload => {
    return {
        ...(description.active !== undefined) && {active: description.active},
        alternativeDescriptions: description.alternativeDescriptions.map((item) => ({language: item.language, description: item.description})),
        marketId: description.marketId,
        categoryBrickId: description.category.id,
        mainDescriptions: description.mainDescriptions.map((item) => ({language: item.language, description: item.description}))
    }
}

export const transformDescriptionUpdatePayload = (UIModel: productDescriptionPayload): productDescriptionUpdatePayload => {
    return {
        mainDescriptions: UIModel.mainDescriptions.map((item) => ({language: item.language, description: item.description})),
        alternativeDescriptions: UIModel.alternativeDescriptions.map((item) => ({language: item.language, description: item.description})),
        active: UIModel.active
    };
};

export const transformDescriptionForUI = (productDescription: productDescriptionResponseModel): productDescriptionUI => {
    const {active, alternativeDescriptions, brickHierarchy, brickId, brickName, id, marketId, mainDescriptions, used} = productDescription;
    return {
        active,
        alternativeDescriptions: alternativeDescriptions.map((item) => (
            {
                language: item.language,
                description: item.description,
                UIId: uuid()
            }
        )),
        brickHierarchy,
        category: {id: brickId, name: brickName, type: categoryType.BRICK},
        id,
        mainDescriptions: mainDescriptions.map((item) => (
            {
                language: item.language,
                description: item.description,
                UIId: uuid()
            }
        )),
        marketId,
        used
    }
};