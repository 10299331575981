import './ProductDescriptionDeactivation.scss'

import { Alert, Checkbox } from '@mui/material';
import { useFormatMessage } from 'utils/translate';
import { FormattedMessage } from 'react-intl';
import { productDescriptionUI } from 'shared/models/productDescription.model';

type ProductDescriptionDeactivationProps = Pick<productDescriptionUI, "active" | "alternativeDescriptions" | "id" | "mainDescriptions" | "used"> & {handleActiveChange: (checked: boolean) => void, isCreatingNew: boolean};

const ProductDescriptionDeactivation = ({ active, alternativeDescriptions, handleActiveChange, id, isCreatingNew, mainDescriptions, used }:ProductDescriptionDeactivationProps) => {
    const translate = useFormatMessage();

    return (
        <div className="productDescriptionDeactivationRoot">
            <div>
                <FormattedMessage
                    id='b.deactivateProductDescription'/>
            </div>
            <div className="buttonContainer">
                <FormattedMessage id="a.active"/><Checkbox checked={active} disabled={used || isCreatingNew} color="primary" onChange={(e) => handleActiveChange(e.target.checked)}/>
                {used && !isCreatingNew ? <Alert className="alert" severity="info">{translate({id: 'b.deactivateProductDescriptionExplanation'})}</Alert> : null}
            </div>
        </div>
    )
}

export default ProductDescriptionDeactivation;