import './PromotionsMultiSuggestion.scss';

import {useState} from 'react';
import {useFormatMessage} from 'utils/translate';
import {Button, Checkbox} from '@mui/material';
import {PaperX} from 'components/PaperX';
import {FramePreview} from 'components/gfx';
import {promotionSuggestionsForFrame} from 'shared/models/promotion.model';
import PromotionItem from 'modules/Advertisement/FrameDescription/PromotionsSuggestions/PromotionItem';
import FrameDescriptionReportDialog, {reportIssuesType} from 'modules/Advertisement/FrameDescription/FrameDescriptionReportDialog';

interface PromotionsMultiSuggestionProps {
    data: promotionSuggestionsForFrame,
    isSelected: boolean,
    refetch: () => void,
    onClick: (frameId: string) => void
}
const PromotionsMultiSuggestion = (props: PromotionsMultiSuggestionProps) => {
    const {frameId, promotions} = props.data;
    const translate = useFormatMessage();

    const [isReportDialogOpen, setIsReportDialogOpen] = useState<boolean>(false);

    const handleReportPromotionSuccess = () => {
        props.refetch();
        setIsReportDialogOpen(false);
    }

    return (
        <PaperX key={frameId} className={`promotionsMultiSuggestionRoot _scrollY _fullWidth ${props.isSelected ? 'promotionSelected' : ''}`.trim()}>
            <div className="promotionsBtnContainer">
                <Checkbox checked={props.isSelected} color="primary" onClick={() => props.onClick(frameId)}/>
                <Button variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => setIsReportDialogOpen(true)}>
                            {translate({id: 'frameDescription.reportAdvertisement'})}
                </Button>
            </div>
            <div className="promotionsListDetails" onClick={() => props.onClick(frameId)}>
                <FramePreview frameId={frameId}/>
                {promotions.map((promotion, index) => <PromotionItem key={promotion.id}
                                                                     isLast={promotions.length -1 === index}
                                                                     data={promotion}/>)
                }
            </div>
            <FrameDescriptionReportDialog open={isReportDialogOpen}
                                          frameId={frameId}
                                          onClose={() => setIsReportDialogOpen(false)}
                                          onSuccess={handleReportPromotionSuccess}
                                          reportType={reportIssuesType.advertisement}
                />
        </PaperX>
    );
};

export default PromotionsMultiSuggestion;