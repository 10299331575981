import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormControl, InputLabel, MenuItem} from '@mui/material';
import Select from '@mui/material/Select';
import {useFormatMessage} from 'utils/translate';
import {AttributeError} from 'components/Attribute/AttributeHelpers';

interface PriceModeSelectorProps {
    isGross: boolean,
    onChange: (isGross: boolean) => void,
    required?: boolean,
}

const GROSS: string = 'gross';
const NET: string = 'net';

const PriceModeSelector = ({onChange, required, isGross}: PriceModeSelectorProps) => {
    const translate = useFormatMessage();

    const [invalid, setInvalid] = useState<boolean>(false);
    const [optionValue, setOptionValue] = useState<string>(GROSS);

    useEffect(() => {
        if (isGross === true) {
            setOptionValue(GROSS);
        } else if (isGross === false) {
            setOptionValue(NET);
        } else {
            setInvalid(true);
            console.warn('improper value provided, setting "true" by default');
        }
    }, [isGross]);

    if (invalid) {
        return (
            <AttributeError errorLabel="headerData.invalidPriceMode" textmode/>
        );
    }

    return (
        <FormControl required={required}>
            <InputLabel color="primary">
                <FormattedMessage id="b.priceType"/>
            </InputLabel>
            <Select color="primary"
                    value={optionValue}
                    required={required}
                    onChange={(e) => onChange(e.target.value === GROSS)}
                    label={translate({id: 'b.priceType'})}
            >
                <MenuItem value={GROSS}><FormattedMessage id="b.priceGross"/></MenuItem>
                <MenuItem value={NET}><FormattedMessage id="b.priceNet"/></MenuItem>
            </Select>
        </FormControl>
    );
};

export default PriceModeSelector;