import {useState} from 'react';
import {Dialog, DialogActions, DialogContent} from '@mui/material';
import ButtonClose from 'components/Buttons/ButtonClose';
import MyAdvertisements from './MyAdvertisements';
import MyAdvertisementsInfo from './MyAdvertisementsInfo';
import { DialogAuxiliaryActions } from 'components/DialogAuxiliaryActions';
import { NavigationMenuButton } from 'modules/NavigationMenu';
import {advertisementsOverviewObject} from 'shared/models/advertisement.model';
import {dachCountries} from 'shared/reducers/countryMarketConst';

interface MyAdvertisementsDialogProps {
    country: string,
    onClose: () => void,
    open: boolean
}

const MyAdvertisementsDialog = (props: MyAdvertisementsDialogProps) => {
    const {country, onClose, open} = props;

    const [advertisementsQty, setAdvertisementsQty] = useState<number>(0);
    const [manuallyCreatedAdvertisementsQty, setManuallyCreatedAdvertisementsQty] = useState<number>(0);

    const handleDataChange = (data: advertisementsOverviewObject[]) => {
        const uniqueDataByAdvertisementId: advertisementsOverviewObject[] = data.filter((item, index, self) => index === self.findIndex((obj) => obj.advertisementId === item.advertisementId));
        setAdvertisementsQty(data.length);
        setManuallyCreatedAdvertisementsQty(uniqueDataByAdvertisementId.filter(item => item.manuallyCreated === true).length);
    };

    return (
        <Dialog className="_dialog-likeView" open={open} fullScreen>
            <DialogContent className="_directionCol">
                <MyAdvertisements onDataChange={handleDataChange}/>
            </DialogContent>
            <DialogActions>
                <DialogAuxiliaryActions>
                    <NavigationMenuButton/>
                    {dachCountries.includes(country) && <MyAdvertisementsInfo advertisementsQty={advertisementsQty} 
                                                                              manuallyCreatedAdvertisementsQty={manuallyCreatedAdvertisementsQty}/>}
                </DialogAuxiliaryActions>
                <ButtonClose onClick={onClose}/>
            </DialogActions>
        </Dialog>
    );
};

export default MyAdvertisementsDialog;