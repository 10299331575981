import './ProductSearchHelpers.scss';

import {useEffect, useState} from "react";
import {FormattedMessage} from 'react-intl';
import Highlighter from 'react-highlight-words';
import {characteristicUI, productSearchResultUI} from 'shared/models/product.model';
import {PROMOTION_TYPES} from 'shared/models/promotion.model';
import {handyAttributes} from 'shared/handyAttributes';

interface productSearchCardProps {
    data: productSearchResultUI,
    highlight?: boolean,
    literalsTranslations: string[]
    onClick: (any, productSearchResult) => void,
    searchWords: string[],
}

export const ProductSearchCard = ({data, highlight, onClick, searchWords, literalsTranslations}: productSearchCardProps) =>
    <div className={highlight ? 'productSearchCardRoot highlight' : 'productSearchCardRoot'} onClick={(e) => onClick(e, data)}>
        <ProductSearchCardContent data={data} searchWords={searchWords} literalsTranslations={literalsTranslations}/>
    </div>;

interface productSearchCardContentProps {
    data: productSearchResultUI,
    searchWords: string[],
    literalsTranslations: string[]
}

const ProductSearchCardContent = ({data, searchWords, literalsTranslations}: productSearchCardContentProps) =>
    <>
        <div className="attributesSpread">
            <div>
                {data.brand && <span className="productBpcoValue _bold"><Highlighter textToHighlight={data.brand} searchWords={searchWords} autoEscape={true} highlightClassName={"highlightColor"}/></span>}
                {data.category && <span className="productBpcoValue"><Highlighter textToHighlight={data.category} searchWords={searchWords} autoEscape={true} highlightClassName={"highlightColor"}/></span>}
            </div>
            <div>
                {!data.isTemplate && data.promotionObjectType === PROMOTION_TYPES.PRODUCT && <div><FormattedMessage id="product.relatedPromotions"/>: <span className="_bold">{data.relatedPromotionsCount}</span></div>}
                {data.isTemplate && <span className="productBpcoValue isTemplate _bold"><FormattedMessage id="product.isTemplate"/></span>}
            </div>
        </div>
        <div className="attributesSpread">
            <div>
                <span className="productBpcoValue _bold">
                    <Highlighter textToHighlight={data.description}
                                 searchWords={searchWords}
                                 autoEscape={true}
                                 highlightClassName={"highlightColor"}/>
                </span>
            </div>
        </div>
        <CharacteristicsList characteristics={data.characteristics} searchWords={searchWords} literalsTranslations={literalsTranslations}/>
    </>;

interface characteristicsListProps {
    characteristics: characteristicUI[],
    searchWords: string[],
    literalsTranslations: string[]
}

const CharacteristicsList = ({characteristics, searchWords, literalsTranslations}: characteristicsListProps) => {
    const [filteredCharacteristics, setFilteredCharacteristics] = useState<characteristicUI[]>([]);

    useEffect(() => {
        const newCharacteristics = characteristics.filter((item) => !literalsTranslations.includes(item.valueId) && item.name && item.value);
        setFilteredCharacteristics(newCharacteristics);
    },[characteristics,literalsTranslations]);

    return (
        <ul className="characteristicsRootSlim">
            {filteredCharacteristics.map((item) => item.id !== handyAttributes.description ? <li className="characteristicItem" key={item.id}>
                <span className={item.uniq ? 'characteristic uniq' : 'characteristic'}>
                    <Highlighter textToHighlight={item.name} searchWords={searchWords} autoEscape={true} highlightClassName={"highlightColor"}/>:
                    <span className="attributeValues"><Highlighter textToHighlight={item.value} searchWords={searchWords}
                                                         autoEscape={true} highlightClassName={"highlightColor"}/></span>
                </span>
            </li> : null)}
        </ul>
    );
}
