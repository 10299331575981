/* eslint-disable react-hooks/exhaustive-deps */
import './AdvertisementBrowserMedia.scss';

import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import {PaperX} from 'components/PaperX';
import {paths} from 'paths';
import {useSnackbar} from 'notistack';
import {
    API_STATUSES,
    API_SPOT_AND_BROADCAST
} from 'config/api/constants';
import {promotionUI} from 'shared/models/promotion.model';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {getId, getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {arePromotionsValid} from 'modules/Advertisement/FrameDescription/FrameDescriptionMVP';
import MediaSummary, {buildMediaSummary} from 'components/MediaSummary/MediaSummary';
import {spotResponseModel, spotStatus, spotTemplate, mediaSummary, promotionMediumType} from 'shared/models/media.model';
import MediaAudiofile from 'modules/Medium/MediaAudiofile/MediaAudiofile';
import {IRootState} from 'shared/reducers';
import AdvertisementBrowserMediaSummary from './AdvertisementBrowserMediaSummary';
import AdvertisementBrowserEditMediaPromotions from './AdvertisementBrowserEditMediaPromotions';
import {usePutPromotionsForMedia} from 'modules/Advertisement/AdvertisementSpotOverview/advertisementsMediaOverviewAPI';
import {releaseMediumLock} from 'utils/lockUtils';

const AdvertisementBrowserMedia = ({history})  => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    const spotDataApiGet = useApi('get', {errMsg: 'media.spotFetchErr'});

    const [isTableMode, setIsTableMode] = useState<boolean>(true);
    const [returnPath, setReturnPath] = useState<string>(paths.home);
    const [mediumData, setMediumData] = useState<spotResponseModel>(spotTemplate);
    const [mediaHeaderDataSummary, setMediaHeaderDataSummary] = useState<mediaSummary>(null);
    const [mediumId, setMediumId] = useState<string>(null);
    const [promotions2save, setPromotions2save] = useState<promotionUI[]>([]);

    const [newProductDrawerOpen, setNewProductDrawerOpen] = useState<boolean>(false);
    const [newBPCODrawerOpen, setNewBPCODrawerOpen] = useState<boolean>(false);
    const [isPromotionsMultiSuggestionsOpen, setIsPromotionsMultiSuggestionsOpen] = useState<boolean>(false);

    useEffect(() => {
        const fromUrl = getURLParam('from');
        if (fromUrl) setReturnPath(fromUrl);
        const spotId = getId();
        spotDataApiGet.call(`${API_SPOT_AND_BROADCAST}/${spotId}?acquireLock=false`);
    }, []);

    useEffect(() => {
        if (isPromotionsMultiSuggestionsOpen) setIsPromotionsMultiSuggestionsOpen(false);
    }, [isPromotionsMultiSuggestionsOpen]);

    useEffect(() => {
        if (spotDataApiGet.status === API_STATUSES.IDLE) {
            if (spotDataApiGet.data) {
                const response = spotDataApiGet.data;
                if (response.spotId) {
                    setMediumId(response.spotId);
                }
                const {headerData} = response;
                setMediumData(spotDataApiGet.data);
                setMediaHeaderDataSummary(buildMediaSummary(headerData));
            } else {
                setMediumData(spotTemplate);
                enqueueSnackbar(translate({id: 'media.errNoMedia'}), {variant: 'warning'});
                goBack();
            }
        }
        if (spotDataApiGet.status === API_STATUSES.ERROR && spotDataApiGet.errorCode === 409) {
            enqueueSnackbar(`${translate({id: 'media.spotLocked'})}`, {variant: 'error', persist: false});
            goBack();
        }
    }, [spotDataApiGet.status]);

    const handleUpdateSuccess = () => setIsTableMode(true);

    const updatePromotionsMediaAPI = usePutPromotionsForMedia(mediumId, promotions2save, mediumData.mediumType as unknown as promotionMediumType, handleUpdateSuccess); // todo solve type in MPM-3475, for now in this component it's ok, as types are identical for spots in both cases

    const handleSubmit = () => {
        releaseMediumLock();
        updatePromotionsMediaAPI.mutate();
    }

    const handleCloseEditBtn = () => {
        releaseMediumLock();
        setIsTableMode(true);
    };

    const handleModifyPromotions = (modifiedPromotions: promotionUI[]) => {
        setPromotions2save(modifiedPromotions);
    };

    const handleGetError409 = () => {
        enqueueSnackbar(`${translate({id: 'media.spotLocked'})}`, {variant: 'error', persist: false});
        setIsTableMode(true);
    }

    const canSave = (): boolean => arePromotionsValid(promotions2save);

    const canEdit = (): boolean => {
        if (mediumData.status === spotStatus.DONE || mediumData.status === spotStatus.VERIFIED) return true;
        else return false; 
    };

    const goBack = () => history.push(returnPath);

    const openNewProductDrawer = () => setNewProductDrawerOpen(true);
    const openNewBPCODrawer = () => setNewBPCODrawerOpen(true);

    return (
        <div className="viewRoot advertisementBrowserMediaRoot">
            <div className="viewport">
                <LoadingOverlay show={spotDataApiGet.status === API_STATUSES.PENDING || updatePromotionsMediaAPI.isLoading}/>
                <div className="viewContainer _directionRow">
                    <div className="_directionCol mediaDataCol">
                        <div className="_growRelative">
                            <div className="_fillRelative _aboveDrawer">
                                <PaperX className="_fullHeight">
                                    <div className="_formRow audiofileRow">
                                        <MediaAudiofile
                                            country={mediumData.country}
                                            filename={mediumData.metaData.sourceFile}
                                            storageFileName={mediumData.metaData.storageFileName}
                                            isPlayMode
                                            mediumType={mediumData.mediumType}
                                        />
                                    </div>
                                    <div className="_formRow">
                                        <div className="transcriptionReadContainer">
                                                <Typography variant="overline">{translate({id: 'media.transcription'})}</Typography>
                                                <div className='transcriptValue'>
                                                    <Typography variant="body1" paragraph>
                                                        {mediumData.headerData.transcription}
                                                    </Typography>
                                                </div>
                                        </div>
                                    </div>
                                </PaperX>
                            </div>
                        </div>
                        <PaperX className="weldBottom">
                            <MediaSummary data={mediaHeaderDataSummary}/>
                        </PaperX>
                        <PaperX className="weldTop"/>
                    </div>
                    <div className="_directionCol promotionsCol">
                        {isTableMode
                        ?
                            <AdvertisementBrowserMediaSummary mediumId={mediumId}
                                                              lang={selectedLanguage}/>
                        :
                            <AdvertisementBrowserEditMediaPromotions mediumId={mediumId}
                                                                     mediumData={mediumData}
                                                                     selectedLanguage={selectedLanguage}
                                                                     newProductDrawerOpen={newProductDrawerOpen}
                                                                     newBPCODrawerOpen={newBPCODrawerOpen}
                                                                     isPromotionsMultiSuggestionsOpen={isPromotionsMultiSuggestionsOpen}
                                                                     onProductDrawerClose={() => setNewProductDrawerOpen(false)}
                                                                     onBPCODrawerClose={() => setNewBPCODrawerOpen(false)}
                                                                     onPromotionsModified={handleModifyPromotions}
                                                                     on409={handleGetError409}/>
                        }
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    isTableMode
                    ?
                        <>
                            <Button variant="contained" color="primary" onClick={() => setIsTableMode(false)} disabled={!canEdit()}>
                                <FormattedMessage id="a.edit"/>
                            </Button>
                            <ButtonClose onClick={goBack}/>
                        </>
                    :
                        <>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setIsPromotionsMultiSuggestionsOpen(true)}
                                disabled={!(mediumData.country)}
                            >
                                {translate({id: "frameDescription.fetchProdSugTitle"})}
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!canSave()}>
                                <FormattedMessage id="a.submit"/>
                            </Button>
                            <Button variant="outlined" color="primary" tabIndex={-1} onClick={openNewProductDrawer}>{translate({id: 'productSearch.createProduct'})}</Button>
                            <Button variant="outlined" color="primary" tabIndex={-1} onClick={openNewBPCODrawer}>{translate({id: 'productSearch.createBPCO'})}</Button>
                            <Button variant="outlined" color="primary" onClick={handleCloseEditBtn}>
                                <FormattedMessage id="a.cancel"/>
                            </Button>
                        </>
                    }
            />
        </div>
    );
}

export default AdvertisementBrowserMedia;
