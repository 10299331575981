import { dataLangList } from 'modules/Options/OptionsDialog';

const letters = [
    `"a","a","a","a","a","а","ą","ä","à","á","â","ă","b","c","ć","č","ç","d","ď","đ","e","ę","é","ě","è","ê","ë","f","g","h",
    "i","í","î","ï","j","k","l","ľ","ł","m","n","ń","ň","o","ö","ó","ô","ő","œ","p","q","r","ř","s","ś","š","ș","t","ť","ț",
    "u","ü","û","ú","ű","ů","v","w","x","y","ý","ÿ","z","z","ź","ż","ž","ž",
    "β","æ",
    "б","в","г","д","е","ж","з","и","й","к","л","м","н","о","п","р","с","т","у","ф","х","ц","ч","ш","щ","ъ","ь","ю","я"`
]

export const lexicographicalComparator  = (prioritisedLocale?: string) => {
    return (a: string | number | any[], b: string | number | any[]) => {

        let firstWord = a;
        if (Array.isArray(a)) firstWord = a.join('');
        let secondWord = b;
        if (Array.isArray(b)) secondWord = b.join('');

        if (typeof firstWord === 'number' && typeof secondWord === 'number') return firstWord - secondWord;
        if (typeof firstWord === 'number' && typeof secondWord !== 'number') return -1;
        if (typeof firstWord !== 'number' && typeof secondWord === 'number') return 1;

        if (typeof firstWord !== 'string' && typeof secondWord === 'string') return 1;
        if (typeof firstWord === 'string' && typeof secondWord !== 'string') return -1;
        if (typeof firstWord !== 'string' && typeof secondWord !== 'string') return 0;

        if (typeof firstWord === 'string' && typeof secondWord === 'string') {

            const minLength = Math.min(firstWord.length, secondWord.length);

            const locales = [
                ...(prioritisedLocale ? [prioritisedLocale] : []),
                ...dataLangList.filter(item => item !== prioritisedLocale)
            ]

            const collatorComparator = new Intl.Collator(locales, {sensitivity: 'accent'}).compare;

            for (let i = 0; i < minLength; i++) {
                const charA = firstWord[i];
                const charB = secondWord[i];

                const aIndex = charA ? letters.indexOf(charA.toLowerCase()) : -1;
                const bIndex = charB ? letters.indexOf(charB.toLowerCase()) : -1;

                if (aIndex && bIndex && aIndex !== bIndex) return letters.indexOf(charA) - letters.indexOf(charB)

                const collatorResult = collatorComparator(charA, charB);

                if (collatorResult) return collatorResult;
            }

            // If all compared characters are equal, shorter string comes first
            return firstWord.length - secondWord.length;
        }
    }
}
