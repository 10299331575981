import './MediumProposals.scss';
import {useFormatMessage} from 'utils/translate';
import {transcriptProposal} from 'shared/models/media.model';

interface MediumProposalsProps {
    transcriptProposals: transcriptProposal[],
    handleSelectProposal: (proposal: transcriptProposal) => void
}

const MediumProposals = ({transcriptProposals, handleSelectProposal}: MediumProposalsProps) => {
    const translate = useFormatMessage();

    return ( 
        <div className="mediumProposalsRoot">
            <p className="mediumProposalsTitle">{translate({id: 'media.transcriptProposals'})}</p>
            {transcriptProposals.length > 0 && <div className="mediumProposalsContainer">
                {transcriptProposals.map((proposal, i) => (
                    <div key={i} className="mediumProposalItem" onClick={() => handleSelectProposal(proposal)}>
                        {proposal.text}
                    </div>
                ))}
            </div>}
        </div>
   )
}

export default MediumProposals;