/* eslint-disable react-hooks/exhaustive-deps */
import './PromotionsMultiSuggestions.scss';

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import axios, {CancelTokenSource} from 'axios';
import {FormattedMessage} from 'react-intl';
import {useSnackbar} from 'notistack';
import {Button, Drawer, Tab, Tabs} from '@mui/material';
import {API_PROMOTIONS_MULTI_SUGGESTIONS} from 'config/api/constants';
import {promotionSuggestionsForFrame, promotionMultiSuggestionsResponseModel, promotionSuggestionsResponseModel} from 'shared/models/promotion.model';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import ButtonClose from 'components/Buttons/ButtonClose';
import Footer from 'components/Footer';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import PromotionsSuggestion from './PromotionsMultiSuggestion';

interface PromotionsMultiSuggestionsProps {
    countryId: string
    spotId: string,
    onClick: (promotionsForFrame: promotionSuggestionsForFrame[]) => void,
    onClose?: () => void,
    forceOpen: boolean,
    initiallyOpen?: boolean,
}

const PromotionsMultiSuggestions = (props: PromotionsMultiSuggestionsProps) => {
    const {countryId, spotId, onClick, onClose, forceOpen, initiallyOpen = false} = props;
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    const [open, setOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shouldUpdate, setShouldUpdate] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const iterationNumber: number = 3;

    const [activeTab, setActiveTab] = useState<number | undefined>(undefined);
    const [data, setData] = useState<promotionMultiSuggestionsResponseModel[]>([]);
    const [currentSuggestions, setCurrentSuggestions] = useState<promotionSuggestionsResponseModel>([]);
    const [selectedSuggestions, setSelectedSuggestions] = useState<promotionSuggestionsResponseModel>([]);

    const cancelToken = useRef<CancelTokenSource | null>(null);

    const cancelQuery = () => {
        if (cancelToken.current) cancelToken.current.cancel();
    };

    useEffect(cancelQuery, []);

    useEffect(() => {
        if (activeTab !== undefined) {
            setCurrentIndex(0);
            setCurrentSuggestions(data[activeTab]?.proposals.slice(0, iterationNumber).flat() || []);
        }
    }, [activeTab]);

    useEffect(() => {
        if (spotId && countryId && initiallyOpen) {
            getProductSuggestions(spotId, countryId);
        } else cancelQuery();
    }, [spotId, countryId, initiallyOpen]);

    useEffect(() => {
        if (forceOpen) {
            handleClearData();
            if (!(data[activeTab]?.proposals.length)) {
                spotId && getProductSuggestions(spotId, countryId);
            }
        }
    }, [forceOpen]);

    useEffect(() => {
        if (shouldUpdate && spotId && countryId) {
            getProductSuggestions(spotId, countryId);
        }
    }, [shouldUpdate]);

    const handleClearData = () => {
        setData([]);
        setCurrentIndex(0);
        setCurrentSuggestions([]);
        setSelectedSuggestions([]);
        setOpen(true);
    }

    const getProductSuggestions = (spotId: string, countryId: string) => {
        cancelQuery();
        setIsLoading(true);
        handleClearData();
        axios.get<promotionMultiSuggestionsResponseModel[]>(API_PROMOTIONS_MULTI_SUGGESTIONS(spotId, selectedLanguage), {
                cancelToken: cancelToken.current?.token
            })
            .then((response) => {
                if (responseValidation(response.data) && response.data?.length) {
                    setData(response.data);
                    setActiveTab(0);
                    setCurrentSuggestions(response.data[0].proposals.slice(0, iterationNumber).flat() || []);
                } else {
                    const message: string = response.status === 202 ? 'frameDescription.framePrepNotFinished' : 'frameDescription.noProposalsForFrame';
                    setOpen(false);
                    enqueueSnackbar(`${translate({id: message})}`, {variant: 'info', persist: false});
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.error(e);
                    setOpen(false);
                    enqueueSnackbar(translate({id: 'frameDescription.fetchPromoSugErr'}), {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleSelectSuggestion = (frameId: string) => {
        setSelectedSuggestions((prevSelectedSuggestion: promotionSuggestionsResponseModel) => {
            const index: number = prevSelectedSuggestion.findIndex((item) => item.frameId === frameId);
            const clickedSuggestion: promotionSuggestionsForFrame = currentSuggestions.find((item) => item.frameId === frameId);
      
            if (index !== -1) {
              return prevSelectedSuggestion.filter((item: promotionSuggestionsForFrame) => item.frameId !== frameId);
            } else {
              return [...prevSelectedSuggestion, clickedSuggestion];
            }
        });
    }

    const handleAddSelected = () => {
        onClick(selectedSuggestions);
        handleClose();
    }

    const handleClose = () => {
        setData([]);
        setOpen(false);
        onClose();
    }

    const handleNextSuggestions = () => {
        const newIndex: number = currentIndex + iterationNumber;
        if (newIndex < data[activeTab]?.proposals.length) {
            setCurrentIndex(newIndex);
            setCurrentSuggestions(data[activeTab]?.proposals.slice(newIndex, newIndex + iterationNumber).flat() || []);
        }
    }

    const handlePrevSuggestions = () => {
        const newIndex: number = currentIndex - iterationNumber;
        if (newIndex < data[activeTab]?.proposals.length) {
            setCurrentIndex(newIndex);
            setCurrentSuggestions(data[activeTab]?.proposals.slice(newIndex, newIndex + iterationNumber).flat() || []);
        }
    }

    const handleTabChange = (e, index: number) => setActiveTab(index);

    return (
        <Drawer className="mediaDescriptionDrawerRoot"
                anchor="right"
                open={open}
                onClose={handleClose}
                hideBackdrop
        >
            <LoadingOverlay show={isLoading}/>
            <PaperX>
                <Tabs value={activeTab} onChange={handleTabChange} color="secondary">
                    {data.map((item, index) => (
                        <Tab key={index} label={item.group} />
                    ))}
                </Tabs>
            </PaperX>
            <div className="_directionRow">
                {currentSuggestions
                    .map((suggestion) => <PromotionsSuggestion key={suggestion.frameId}
                                                               isSelected={!!selectedSuggestions.find((item) => item.frameId === suggestion.frameId)}
                                                               data={suggestion}
                                                               onClick={(frameId) => handleSelectSuggestion(frameId)}
                                                               refetch={() => setShouldUpdate(shouldUpdate + 1)}/>)
                }
            </div>
            <Footer hideNavigationMenu
                    actionsRight={
                        <>
                            <Button variant="outlined" onClick={handlePrevSuggestions} disabled={isLoading || currentIndex === 0}>
                                {translate({id: 'a.prev'})}
                            </Button>
                            <Button variant="outlined" onClick={handleNextSuggestions} disabled={isLoading || currentIndex+iterationNumber >= data[activeTab]?.proposals.length}>
                                {translate({id: 'a.next'})}
                            </Button>
                            <Button variant="contained" onClick={handleAddSelected} disabled={isLoading || !selectedSuggestions?.length}>
                                <FormattedMessage id="a.addSelected" values={{count: selectedSuggestions?.length}}/>
                            </Button>
                            <ButtonClose onClick={handleClose}/>
                        </>
                    }
            />
        </Drawer>
    );
};

export default PromotionsMultiSuggestions;