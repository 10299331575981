import {RowClickedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {handyAttributes, hardcodedPercent} from 'shared/handyAttributes';
import {
    PROMOTION_TYPES,
    promotionMediaTableRow,
    promotionMediaTableRowBE,
    promotionTableRow,
    promotionTableRowBE
} from 'shared/models/promotion.model';
import {shortCharacteristic} from 'shared/models/product.model';
import {ActiveFlagCell} from 'components/AgTable/renderers';
import {validity} from 'shared/models/validity.model';
import AgTable, {localStorageColumnsKey} from 'components/AgTable/AgTable';
import { useFormatMessage } from 'utils/translate';
import AttributesDisplay from 'components/Displays/AttributesDisplay';
import {capacitiesComparator} from 'utils/sortCapacities';
import {isSomeEnum} from 'utils/isSomeEnum';
import {tradingUnitsComparator} from 'utils/sortTradingUnits';
import {useSelector} from 'react-redux';
import {IRootState} from 'shared/reducers';

export enum advertisementsTableColumn {
  brand = 'brand',
  description = 'description',
  category = 'category',
  mainAttributes = 'mainAttributes',
  variety = 'variety',
  contentOfTradingUnit = 'contentOfTradingUnit',
  capacity = 'capacity',
  promotionalPrice = 'promotionalPrice',
  regularPrice = 'regularPrice',
  relativeDiscount = 'relativeDiscount',
  absoluteDiscount = 'absoluteDiscount',
  generalInformation = 'generalInformation',
  isTemplate = 'isTemplate',
  frameValidity = 'frameValidity',
  validityDates = 'validityDates',
  giveAwayBundling = 'giveAwayBundling',
}

enum additionalRequiredProperties {
  currency = 'currency'
}

interface advertisementsTableColDef extends ColDef {
  field: advertisementsTableColumn;
}

type advertisementsTableBEData = promotionTableRowBE[] | promotionMediaTableRowBE[];
type advertisementsTableTransformedData = Partial<promotionTableRow>[] | Partial<promotionMediaTableRow>[];
type advertisementsTableTransformedRow = Partial<promotionTableRow> | Partial<promotionMediaTableRow>;

interface AdvertisementsTableProps {
    rowData: advertisementsTableBEData,
    columns?: advertisementsTableColumn[],
    localStorageColumnsKey: localStorageColumnsKey,
    onRowClick?: (id: string) => void,
}

const AdvertisementsTable = (props:AdvertisementsTableProps) => {
    const translate = useFormatMessage();
    const langData = useSelector((state: IRootState) => state.userProfile.langData);

  const colDefs: advertisementsTableColDef[] = [
      { field: advertisementsTableColumn.brand, headerName: translate({ id: 'b.brand' }), lockVisible: true },
      { field: advertisementsTableColumn.description, headerName: translate({ id: 'b.descr' }) },
      { field: advertisementsTableColumn.category, headerName: translate({id: 'b.category' }), flex: 2 },
      { field: advertisementsTableColumn.mainAttributes, headerName: translate({id: 'attributes.label' }), flex: 3,
        cellRenderer: (params) => <AttributesDisplay data={params.data.mainAttributes}/>},
      { field: advertisementsTableColumn.variety, headerName: translate({id: 'b.variety' }), flex: 2 },
      { field: advertisementsTableColumn.contentOfTradingUnit, headerName: translate({id: 'b.contentOfTradingUnit' }), flex: 2,
        comparator: (a: advertisementsTableTransformedRow['contentOfTradingUnit'], b: advertisementsTableTransformedRow['contentOfTradingUnit']) => tradingUnitsComparator(a, b, langData)},
      { field: advertisementsTableColumn.capacity, headerName: translate({id: 'b.capacity' }), flex: 2,
        comparator: (a: advertisementsTableTransformedRow['capacity'], b: advertisementsTableTransformedRow['capacity']) => capacitiesComparator(a, b, langData)},
      { field: advertisementsTableColumn.promotionalPrice, headerName: translate({ id: 'b.promotionalPrice'})},
      { field: advertisementsTableColumn.regularPrice, headerName: translate({ id: 'b.regularPrice'})},
      { field: advertisementsTableColumn.relativeDiscount, headerName: translate({ id: 'b.relativeDiscount'}),
        cellRenderer: (params) => params.value ? `${params.value} ${hardcodedPercent}` : ''},
      { field: advertisementsTableColumn.absoluteDiscount, headerName: translate({ id: 'b.absoluteDiscount'}),
        cellRenderer: (params) => params.value ? `${params.value} ${params.data.currency}`: ''},
      { field: advertisementsTableColumn.generalInformation, headerName: translate({ id: 'b.general' }), flex: 4},
      { field: advertisementsTableColumn.isTemplate, headerName: translate({ id: 'product.isTemplate' }),
        width: 160, cellClass: 'active-flag-cell',
        cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>)
      },
      { field: advertisementsTableColumn.frameValidity, headerName: translate({ id: 'frame.validity'}), flex: 2,
        valueGetter: (params) => {
          const {frameValidFrom, frameValidTo} = params.data;
          return frameValidFrom || frameValidTo ? `${frameValidFrom} - ${frameValidTo}` : '';
        }},
      { field: advertisementsTableColumn.validityDates, headerName: translate({ id: 'promotion.validity'}), flex: 2,
        valueGetter: (params) => params.data.validityDates?.join(', '),
        tooltipField: 'validityDates'
      },
      { field: advertisementsTableColumn.giveAwayBundling, headerName: translate({ id: 'productSearch.giveAwayBundling' }), flex: 2},
    ]

    let filteredColDefs: advertisementsTableColDef[] = colDefs;

    if (props.columns) {
      filteredColDefs = props.columns.map(col => colDefs.find(colDef => colDef.field === col));
    }

    const defaultColDef = {
        flex: 1,
        resizable: true,
        suppressMenu: false,
        sortable: true
    };

    return (
      <AgTable
        columnDefs={filteredColDefs}
        defaultColDef={defaultColDef}
        localStorageColumnsKey={props.localStorageColumnsKey}
        enableBrowserTooltips={true}
        {...props}
        rowData={transformBETableData(props.rowData)}
        onRowClicked={(rowData: RowClickedEvent) => {
            if (props.onRowClick) props.onRowClick(rowData.data.id);
        }}
      />
    );
}

const transformBETableData = (data: advertisementsTableBEData): advertisementsTableTransformedData => {

    return data.map((row) => {
        const isProduct: boolean = row.promotionObjectType === PROMOTION_TYPES.PRODUCT;

        let generalInformation = [];
        if (row.mpu) generalInformation.push(row.mpu);
        generalInformation = generalInformation.concat(row.promotionTypes).concat(row.themeSeasons).concat(row.countryThemedWeeks).concat(row.qualitySeals);

        let giveAwayBundlingValues = [];
        if (row.giveAwayBundledProducts && row.giveAwayBundledProducts.length > 0) {
            giveAwayBundlingValues.push(`${row?.giveAwayBundledProducts?.map(item => item.brand ? item.brand : item).join(', ')}`);
        }

        let description: string = '';
        if (isProduct) {
            description = row.product.description;
        } else {
            description = row.bpco.description;
        }

        const mainAttributes = isProduct ? row.product.characteristics : row.bpco.characteristics
        const filteredMainAttributes = mainAttributes.filter(attr => ![handyAttributes.variety, handyAttributes.contentOfTradingUnit, handyAttributes.capacity].includes(attr.id));
        const variety = mainAttributes.filter(attr => attr.id === handyAttributes.variety)[0]?.value ?? '';
        const contentOfTradingUnit = mainAttributes.filter(attr => attr.id === handyAttributes.contentOfTradingUnit)[0]?.value ?? '';
        const capacity = mainAttributes.filter(attr => attr.id === handyAttributes.capacity)[0]?.value ?? '';

        const flatRow: promotionTableRow | promotionMediaTableRow = {
            absoluteDiscount: row.absoluteDiscount,
            brand: isProduct ? row.product.brand : row.bpco.brand,
            category: isProduct ? row.product.category : row.bpco.category,
            currency: row.currency,
            description,
            generalInformation,
            id: row.id,
            isTemplate: isProduct ? row.product.isTemplate : false,
            mainAttributes: filteredMainAttributes,
            variety,
            contentOfTradingUnit,
            capacity,
            promotionalPrice: row.promotionalPrice,
            regularPrice: row.regularPrice,
            relativeDiscount: row.relativeDiscount,
            frameValidFrom: row.frameValidFrom,
            frameValidTo: row.frameValidTo,
            giveAwayBundling: giveAwayBundlingValues,
            validityDates: row.validityDates.map((item) => makeValidityString(item))
        };

        const isAdvertisementsTableColumn = isSomeEnum(advertisementsTableColumn);

        const keysFromData: advertisementsTableColumn[] = [...new Set(data.flatMap(promotion => Object.keys(promotion)))].filter(isAdvertisementsTableColumn);
        const additionalKeys: (advertisementsTableColumn | additionalRequiredProperties)[] = [
            advertisementsTableColumn.brand,
            advertisementsTableColumn.category,
            advertisementsTableColumn.description,
            advertisementsTableColumn.generalInformation,
            advertisementsTableColumn.isTemplate,
            advertisementsTableColumn.mainAttributes,
            advertisementsTableColumn.variety,
            advertisementsTableColumn.contentOfTradingUnit,
            advertisementsTableColumn.capacity,
            advertisementsTableColumn.giveAwayBundling,
            additionalRequiredProperties.currency,
        ];

        const flatRowFilteredByKeysInRowData: advertisementsTableTransformedRow = Object.fromEntries( // this lets us e.g. skip frameValidFrom and frameValidTo when they are not in row data as in advertisementMedia use cases, so we always provide minimal dataset
            [...keysFromData, ...additionalKeys].map(dataKey => [dataKey, flatRow[dataKey]])
        );

        return flatRowFilteredByKeysInRowData;
    });
};

export const extractMainAttributes = (characteristics: shortCharacteristic[]): string => characteristics
    .map((item) => item.value)
    .join(', ');

const makeValidityString = (validityDates: validity): string => {
    if (validityDates) {
        if (validityDates.validFrom === validityDates.validTo) {
            return validityDates.validFrom;
        } else {
            return `${validityDates.validFrom} - ${validityDates.validTo}`;
        }
    }
    return '';
}

export default AdvertisementsTable;